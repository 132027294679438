import { default as React, useState, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { ToolHeader } from '../../common'
import { renderEditSettingsButton, renderHiddenModalButton } from '../../common/Utils'
import Dialog from '../../common/Dialog'
import ModelColorsPreview from './ModelColorsPreview'

import { unique } from '../../../entries/utils'

const RenderedModelColorsTool = ({ onDataChange, data, brands, toolInfo, uniqueId }) => {
  const [show, setShow] = useState(data.model ? false : true)
  const [manufacturers, setManufacturers] = useState([])
  const [selectedManufacturer, setSelectedManufacturer] = useState(data.manufacturer ?? undefined)
  const [models, setModels] = useState([])
  const [selectedModel, setSelectedModel] = useState(data.model ?? undefined)
  const [selectedModelData, setSelectedModelData] = useState(undefined)
  const [generatedPreview, setGeneratedPreview] = useState(false)

  useEffect(() => {
    const tempManufacturers = []
    brands.map((brand) => tempManufacturers.push({ id: brand.manufacturer_id, name: brand.make }))
    setManufacturers(tempManufacturers)

    if (selectedModel) {
      getSelectedModelData()
    }
  }, [])

  useEffect(() => {
    if (selectedManufacturer) {
      fetch(Routes.manufacturer_models_path(selectedManufacturer) + '.json')
        .then((res) => res.json())
        .then((res) => {
          setModels(res.map((model) => ({ id: model.id, name: model.name })))
        })
    }
  }, [selectedManufacturer])

  function getSelectedModelData() {
    if (selectedModel) {
      fetch(`/models/${selectedModel}.json`)
        .then((res) => res.json())
        .then((res) => {
          setSelectedModelData(res)
          setGeneratedPreview(true)
        })
    }
  }

  return (
    <>
      <ModelColorsPreview
        selectedModelData={selectedModelData}
        generatedPreview={generatedPreview}
      />
      <Dialog title="Model Colors" show={show} closeClickHandler={() => setShow(false)}>
        <ToolHeader {...toolInfo} />
        <div className="pt-3 border-top">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="manufacturer-select">Manufacturer Option</label>
                <select
                  className="form-control"
                  id="manufacturer-select"
                  value={selectedManufacturer}
                  onChange={(e) => {
                    setSelectedManufacturer(e.target.value)
                    setSelectedModel(undefined)
                    setSelectedModelData(undefined)
                    setGeneratedPreview(false)
                    onDataChange({ manufacturer: e.target.value, model: selectedModel })
                  }}
                >
                  <option value="">Select a manufacturer</option>
                  {manufacturers?.map((manufacturer) => (
                    <option key={manufacturer.id} value={`${manufacturer.id}`}>
                      {manufacturer.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {models.length > 0 && (
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="model-select">Model Option</label>
                  <select
                    className="form-control"
                    id="model-select"
                    value={selectedModel}
                    onChange={(e) => {
                      setSelectedModel(e.target.value)
                      setSelectedModelData(undefined)
                      setGeneratedPreview(false)
                      onDataChange({ manufacturer: selectedManufacturer, model: e.target.value })
                    }}
                  >
                    <option value="">Select a model</option>
                    {models?.map((model) => (
                      <option key={model.id} value={`${model.id}`}>
                        {model.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            <div className="col-12">
              <button
                className="btn btn-primary"
                onClick={() => getSelectedModelData()}
                disabled={generatedPreview || !selectedModel}
              >
                Generate Preview
              </button>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
    </>
  )
}

class ModelColorsTool {
  static get toolbox() {
    return {
      title: 'Model Colors',
      icon: `<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"></path>
      </svg>`,
    }
  }

  constructor({ data, config, api }) {
    this.api = api
    this.config = config
    this.uniqueId = unique()

    let defaultValue = {
      manufacturer: undefined,
      model: undefined,
    }

    this.data = Object.keys(data).length > 0 ? data : defaultValue

    this.CSS = {
      wrapper: 'walkthrough-timeline',
    }

    this.nodes = {
      holder: null,
    }

    this.toolInfo = {
      heading: undefined,
      helpText:
        "Select a model and show all the colors associated with that model's variants. The available options are based on OEM websites under your dealership.",
      hideToggle: true,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    rootNode.setAttribute('class', this.CSS.wrapper)
    this.nodes.holder = rootNode

    const onDataChange = (newData) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedModelColorsTool
        onDataChange={onDataChange}
        data={this.data}
        brands={this.config?.brands}
        toolInfo={this.toolInfo}
        uniqueId={this.uniqueId}
      />
    )

    return this.nodes.holder
  }

  /** Create the settings panel for the block */
  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  save() {
    return this.data
  }
}

export default ModelColorsTool
