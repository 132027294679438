import { createRoot } from 'react-dom/client'

import type { DefaultConfigProps, NodesProps, ToolboxProps } from '../../types/toolData.type'
import { useCallback, useState } from 'react'
import Dialog from '../../common/Dialog'
import { renderEditSettingsButton, renderHiddenModalButton } from '../../common/Utils'
import { unique } from '../../../entries/utils'
import { LabeledNumberInput, LabeledSelect, bootstrapColours } from '../../common'
import { Slider } from 'primereact/slider'
import { ColorPicker } from 'primereact/colorpicker'
import { handleColorType } from '../FinanceCalculatorTool/utils'

const RenderedFormComponent = ({ data, onDataChange, uniqueId }) => {
  const [show, setShow] = useState(false)
  const [state, setState] = useState(data)

  /**
   * Update the common fields such as fullWidth
   */
  const syncedStateUpdate = useCallback(
    (item) => {
      setState({
        ...item,
      })
      onDataChange({ ...item })
    },
    [onDataChange]
  )

  return (
    <>
      <hr
        className="mt-0 mb-2"
        style={{
          border: 'none',
          backgroundColor: handleColorType(state.color),
          height: state.height ?? 10,
          borderRadius: `calc(${state.borderRadius}rem / 10)`,
          opacity: `${state.opacity * 10}%`,
        }} // TODO: width as option
      />
      <Dialog title="Horizontal Rule" show={show} closeClickHandler={() => setShow(false)}>
        <div className="row">
          <div className="col-12 col-md-6">
            {/* @ts-ignore */}
            <LabeledNumberInput
              item={state}
              itemName="height"
              label="Height (px)"
              placeholder="Line height..."
              updateItem={syncedStateUpdate}
              min={0}
            />
          </div>
          <div className="col-12 col-md-6">
            <label>Border Radius</label>
            <div className="d-flex align-items-center mb-3">
              <div className="ml-2 mr-3 flex-grow-1 align-self-center">
                <Slider
                  value={state.borderRadius}
                  onChange={(e) => syncedStateUpdate({ ...state, borderRadius: e.value })}
                  step={1}
                  min={0}
                  max={10}
                />
              </div>
              <div className="d-flex align-items-center small font-weight-bold">
                {state.borderRadius}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            {/* @ts-ignore */}
            <LabeledSelect
              controlled={false}
              item={state.color}
              itemName="colorType"
              label="Color"
              customOnChange={(e) =>
                syncedStateUpdate({
                  ...state,
                  color: {
                    ...state.color,
                    colorType: e.target.value,
                  },
                })
              }
              options={[...Array.from(bootstrapColours), 'custom']}
            />
          </div>
          {state.color.colorType === 'custom' && (
            <div className="col-12 col-md-6 d-flex align-items-end mb-3">
              <ColorPicker
                value={state.color.customColor}
                onChange={(e) =>
                  syncedStateUpdate({
                    ...state,
                    color: { ...state.color, customColor: e.value },
                  })
                }
                appendTo="self"
                pt={{
                  input: () => ({
                    style: {
                      height: 38,
                      width: 38,
                    },
                  }),
                }}
              />
              <input
                placeholder="Enter a color hexcode"
                className="form-control ml-1"
                value={state.color.customColor}
                onChange={(e) =>
                  syncedStateUpdate({
                    ...state,
                    color: {
                      ...state.color,
                      customColor: e.target.value,
                    },
                  })
                }
              />
            </div>
          )}
          <div className="col-12 col-md-6">
            <label>Opacity</label>
            <div className="d-flex align-items-center mb-3">
              <div className="ml-2 mr-3 flex-grow-1 align-self-center">
                <Slider
                  value={state.opacity}
                  onChange={(e) => syncedStateUpdate({ ...state, opacity: e.value })}
                  step={1}
                  min={0}
                  max={10}
                />
              </div>
              <div className="d-flex align-items-center small font-weight-bold">
                {state.opacity * 10}%
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
    </>
  )
}

class HorizontalRuleTool {
  private config: DefaultConfigProps
  private uniqueId: string
  private data: {
    height: number
    color: string
    borderRadius: number
    opacity: number
  }
  private nodes: NodesProps

  static get toolbox(): ToolboxProps {
    return {
      title: 'Horizontal Rule',
      icon: '<i class="fa-solid fa-horizontal-rule"/>',
    }
  }

  constructor({ data, config }) {
    this.config = config
    this.uniqueId = unique()

    let defaultData = {
      height: 1,
      borderRadius: 5,
      opacity: 1,
      color: {
        colorType: 'primary',
        customColor: '000000',
      },
    }

    this.data = Object.keys(data).length ? data : defaultData

    this.nodes = {
      holder: null,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    this.nodes.holder = rootNode

    const onDataChange = (newData: object) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedFormComponent
        onDataChange={onDataChange}
        data={this.data}
        uniqueId={this.uniqueId}
      />
    )

    return this.nodes.holder
  }

  /** Create the settings panel for the block */
  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  save() {
    return this.data
  }
}

export default HorizontalRuleTool
