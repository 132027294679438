import { default as React, useCallback, useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import {
  LabeledTextarea,
  LabeledNumberInput,
  LabeledMultipleSelectInput,
  LabeledSelect,
  LabeledCheckbox,
  LabeledInput,
  bootstrapColours,
} from '../../common'
import { renderEditSettingsButton, renderHiddenModalButton, renderTabs } from '../../common/Utils'
import Dialog from '../../common/Dialog'
import { ColorPicker } from 'primereact/colorpicker'

import { capitalise, unique } from '../../../entries/utils'
import { frequencyOptions, handleSliderRules } from './utils'
import FinanceCalculatorPreview, {
  FrequencySelectPreview,
  SliderPreview,
} from './FinanceCalculatorPreview'

const FrequencyEditor = ({
  isActive,
  isModalOpen,
  stepIndex,
  activeIndexState,
  getUpdatedData,
  syncedStateUpdate,
}) => {
  const [frequencyState, setFrequencyState] = useState(getUpdatedData().frequency)

  // Save state updates when changing away from this step
  useEffect(() => {
    if (
      (!isActive && activeIndexState.previousActiveIndex === stepIndex) ||
      (!isModalOpen && activeIndexState.activeIndex === stepIndex)
    ) {
      const updatedData = getUpdatedData()

      syncedStateUpdate({
        ...updatedData,
        frequency: {
          ...frequencyState,
        },
      })
    }
  }, [isActive, isModalOpen])

  // Handle deletion of current default
  useEffect(() => {
    if (!frequencyState.options.includes(frequencyState.default)) {
      setFrequencyState({
        ...frequencyState,
        default: frequencyState.options[0],
      })
    }
  }, [frequencyState])

  return (
    <div className={`row ${isActive ? 'd-flex' : 'd-none'}`}>
      <div className="col-12">
        <h5>Frequency</h5>
        <p>Use the input below to customise the frequency options for your finance calculator.</p>
      </div>
      <div className="col-12">
        <div className="pl-4">
          <LabeledCheckbox
            item={frequencyState}
            itemName="active"
            label={`Show frequency option selector`}
            updateItem={setFrequencyState}
          />
        </div>
      </div>
      {!frequencyState.active && (
        <div className="col-12 mb-3">
          <span className="badge badge-warning">Warning</span>{' '}
          <span className="small">
            If you don't have an active frequency option selector, it is recommended to include the
            value being used for calulations in your disclaimer.
          </span>
        </div>
      )}
      <div className="col-12">
        <LabeledMultipleSelectInput
          item={frequencyState}
          itemName="options"
          label="Options"
          selectOptions={frequencyOptions}
          updateItem={setFrequencyState}
          minOptions={1}
        />
      </div>
      <div className="col-12 col-md-6">
        <LabeledSelect
          item={frequencyState}
          itemName="default"
          options={frequencyOptions.filter((option) =>
            frequencyState.options.includes(option.value)
          )}
          updateItem={setFrequencyState}
        />
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col-12 col-md-6">
            <LabeledSelect
              item={frequencyState.textColor}
              itemName="colorType"
              label="Text Color"
              customOnChange={(e) =>
                setFrequencyState({
                  ...frequencyState,
                  textColor: {
                    ...frequencyState.textColor,
                    colorType: e.target.value,
                  },
                })
              }
              options={[...Array.from(bootstrapColours), 'custom']}
            />
          </div>
          {frequencyState.textColor.colorType === 'custom' && (
            <div className="col-12 col-md-6 d-flex align-items-end mb-3">
              <ColorPicker
                value={frequencyState.textColor.customColor}
                onChange={(e) =>
                  setFrequencyState({
                    ...frequencyState,
                    textColor: { ...frequencyState.textColor, customColor: e.value },
                  })
                }
                appendTo="self"
                pt={{
                  input: () => ({
                    style: {
                      height: 38,
                      width: 38,
                    },
                  }),
                }}
              />
              <input
                placeholder={'Enter a color hexcode'}
                className="form-control ml-1"
                value={frequencyState.textColor.customColor}
                onChange={(e) =>
                  setFrequencyState({
                    ...frequencyState,
                    textColor: {
                      ...frequencyState.textColor,
                      customColor: e.target.value,
                    },
                  })
                }
              />
            </div>
          )}
        </div>
      </div>
      <div className="col-12 mt-4">
        <div className="row justify-content-center">
          <FrequencySelectPreview
            state={getUpdatedData()}
            frequencyState={frequencyState}
            settingsPreview
          />
        </div>
      </div>
    </div>
  )
}

const OtherSettingsEditor = ({
  isActive,
  isModalOpen,
  stepIndex,
  activeIndexState,
  getUpdatedData,
  syncedStateUpdate,
}) => {
  const [otherSettingsState, setOtherSettingsState] = useState({
    repaymentsText: getUpdatedData().repaymentsText,
    disclaimer: getUpdatedData().disclaimer,
    primaryColor: getUpdatedData().primaryColor,
    secondaryColor: getUpdatedData().secondaryColor,
  })

  // Save state updates when changing away from this step
  useEffect(() => {
    if (
      (!isActive && activeIndexState.previousActiveIndex === stepIndex) ||
      (!isModalOpen && activeIndexState.activeIndex === stepIndex)
    ) {
      const updatedData = getUpdatedData()

      syncedStateUpdate({
        ...updatedData,
        repaymentsText: otherSettingsState.repaymentsText,
        disclaimer: otherSettingsState.disclaimer,
        primaryColor: otherSettingsState.primaryColor,
        secondaryColor: otherSettingsState.secondaryColor,
      })
    }
  }, [isActive, isModalOpen])

  return (
    <div className={`row ${isActive ? 'd-flex' : 'd-none'}`}>
      <div className="col-12">
        <h5>Other Settings</h5>
        <p>Use the inputs below to customise the remaining settings for your finance calculator.</p>
        <LabeledInput
          item={otherSettingsState}
          itemName="repaymentsText"
          label="Repayments Text"
          updateItem={setOtherSettingsState}
        />
        <LabeledTextarea
          item={otherSettingsState}
          itemName="disclaimer"
          label={
            <>
              <p className="mb-0">Disclaimer</p>
              {!otherSettingsState.disclaimer && (
                <div className="mb-1">
                  <span className="badge badge-warning">Warning</span>{' '}
                  <span className="small">
                    It is highly recommended to include a disclaimer when using a finance
                    calculator.
                  </span>
                </div>
              )}
            </>
          }
          updateItem={setOtherSettingsState}
        />
        <div className="row">
          <div className="col-12 col-md-6">
            <LabeledSelect
              item={otherSettingsState.primaryColor}
              itemName="colorType"
              label="Primary Color"
              customOnChange={(e) =>
                setOtherSettingsState({
                  ...otherSettingsState,
                  primaryColor: {
                    ...otherSettingsState.primaryColor,
                    colorType: e.target.value,
                  },
                })
              }
              options={[...Array.from(bootstrapColours), 'custom']}
            />
          </div>
          {otherSettingsState.primaryColor.colorType === 'custom' && (
            <div className="col-12 col-md-6 d-flex align-items-end mb-3">
              <ColorPicker
                value={otherSettingsState.primaryColor.customColor}
                onChange={(e) =>
                  setOtherSettingsState({
                    ...otherSettingsState,
                    primaryColor: { ...otherSettingsState.primaryColor, customColor: e.value },
                  })
                }
                appendTo="self"
                pt={{
                  input: () => ({
                    style: {
                      height: 38,
                      width: 38,
                    },
                  }),
                }}
              />
              <input
                placeholder={'Enter a color hexcode'}
                className="form-control ml-1"
                value={otherSettingsState.primaryColor.customColor}
                onChange={(e) =>
                  setOtherSettingsState({
                    ...otherSettingsState,
                    primaryColor: {
                      ...otherSettingsState.primaryColor,
                      customColor: e.target.value,
                    },
                  })
                }
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <LabeledSelect
              item={otherSettingsState.secondaryColor}
              itemName="colorType"
              label="Secondary Color"
              customOnChange={(e) =>
                setOtherSettingsState({
                  ...otherSettingsState,
                  secondaryColor: {
                    ...otherSettingsState.secondaryColor,
                    colorType: e.target.value,
                  },
                })
              }
              options={[...Array.from(bootstrapColours), 'custom']}
            />
          </div>
          {otherSettingsState.secondaryColor.colorType === 'custom' && (
            <div className="col-12 col-md-6 d-flex align-items-end mb-3">
              <ColorPicker
                value={otherSettingsState.secondaryColor.customColor}
                onChange={(e) =>
                  setOtherSettingsState({
                    ...otherSettingsState,
                    secondaryColor: { ...otherSettingsState.secondaryColor, customColor: e.value },
                  })
                }
                appendTo="self"
                pt={{
                  input: () => ({
                    style: {
                      height: 38,
                      width: 38,
                    },
                  }),
                }}
              />
              <input
                placeholder={'Enter a color hexcode'}
                className="form-control ml-1"
                value={otherSettingsState.secondaryColor.customColor}
                onChange={(e) =>
                  setOtherSettingsState({
                    ...otherSettingsState,
                    secondaryColor: {
                      ...otherSettingsState.secondaryColor,
                      customColor: e.target.value,
                    },
                  })
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const SliderEditor = ({
  isActive,
  isModalOpen,
  stepIndex,
  activeIndexState,
  component,
  syncedStateUpdate,
  getUpdatedData,
}) => {
  const [sliderState, setSliderState] = useState(getUpdatedData()[component])

  // Save state updates when changing away from this step
  useEffect(() => {
    if (
      (!isActive && activeIndexState.previousActiveIndex === stepIndex) ||
      (!isModalOpen && activeIndexState.activeIndex === stepIndex)
    ) {
      const updatedData = getUpdatedData()

      const newData = handleSliderRules(sliderState)

      syncedStateUpdate({
        ...updatedData,
        [component]: {
          active: sliderState.active,
          min: newData.min,
          max: newData.max,
          step: newData.step,
          default: newData.defaultVal,
        },
      })
      setSliderState({
        active: sliderState.active,
        min: newData.min,
        max: newData.max,
        step: newData.step,
        default: newData.defaultVal,
      })
    }
  }, [isActive, isModalOpen])

  return (
    <div className={`row ${isActive ? 'd-flex' : 'd-none'}`}>
      <div className="col-12">
        <h5>{capitalise(component)}</h5>
        <p>Use the inputs below to customise the {component} slider for your finance calculator.</p>
      </div>
      <div className="col-12">
        <div className="pl-4">
          <LabeledCheckbox
            item={sliderState}
            itemName="active"
            label={`Show ${component} slider`}
            updateItem={setSliderState}
          />
        </div>
      </div>
      {sliderState.active ? (
        <>
          <div className="col-12 col-md-6">
            <LabeledNumberInput
              item={sliderState}
              itemName="min"
              label="Min"
              placeholder="Minimum value..."
              customOnChange={(e) => {
                setSliderState({
                  ...sliderState,
                  min: parseInt(e.target.value),
                })
              }}
              min={0}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <LabeledNumberInput
              item={sliderState}
              itemName="max"
              label="Max"
              placeholder="Maximum value..."
              customOnChange={(e) => {
                setSliderState({
                  ...sliderState,
                  max: parseInt(e.target.value),
                })
              }}
              min={0}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <LabeledNumberInput
              item={sliderState}
              itemName="step"
              label="Step"
              placeholder="Step value..."
              customOnChange={(e) => {
                setSliderState({
                  ...sliderState,
                  step: parseFloat(e.target.value),
                })
              }}
              min={0}
              step={0.01}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <LabeledNumberInput
              item={sliderState}
              itemName="default"
              label="Default"
              placeholder="default value..."
              customOnChange={(e) => {
                setSliderState({
                  ...sliderState,
                  default: parseFloat(e.target.value),
                })
              }}
              min={0}
            />
          </div>
          <div className="col-12 mt-4">
            <div className="row justify-content-center">
              <SliderPreview
                component={component}
                sliderState={sliderState}
                settingsPreview
                state={getUpdatedData()}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="col-12 mb-3">
            <span className="badge badge-warning">Warning</span>{' '}
            <span className="small">
              If you don't have an active {component} slider, it is recommended to include the value
              being used for calulations in your disclaimer.
            </span>
          </div>
          <div className="col-12 col-md-6">
            <LabeledNumberInput
              item={sliderState}
              itemName="default"
              label="Default"
              placeholder="default value..."
              customOnChange={(e) => {
                setSliderState({
                  ...sliderState,
                  default: parseFloat(e.target.value),
                })
              }}
              min={0}
            />
          </div>
        </>
      )}
    </div>
  )
}

/**
 * FinanceCalculator - A React component for managing and displaying a financing calculator.
 *
 * @param {Object} props.data - The data representing the finance calculator.
 * @param {Function} props.onDataChange - Callback for updating data.
 * @param {number} props.uniqueId - Unique ID for the component.
 * @returns {JSX.Element} - The rendered component.
 */
const RenderedFinanceCalculatorComponent = ({ onDataChange, getUpdatedData, uniqueId }) => {
  const steps = [
    { name: 'Amount', component: SliderEditor },
    { name: 'Term', component: SliderEditor },
    { name: 'Interest', component: SliderEditor },
    { name: 'Frequency', component: FrequencyEditor },
    { name: 'Other', component: OtherSettingsEditor },
  ]

  // State management using React hooks
  const [show, setShow] = useState(false)
  const [activeIndexState, setActiveIndexState] = useState({
    activeIndex: 0,
    previousActiveIndex: undefined,
  })
  const [state, setState] = useState(getUpdatedData())

  const syncedStateUpdate = useCallback(
    (item) => {
      setState({ ...item })
      onDataChange({ ...item })
    },
    [onDataChange]
  )

  return (
    <>
      <FinanceCalculatorPreview state={state} isModalOpen={show} />
      <Dialog title="Finance Calculator" show={show} closeClickHandler={() => setShow(false)}>
        {renderTabs(
          steps.map((step, index) => {
            return {
              name: step.name,
            }
          }),
          activeIndexState,
          setActiveIndexState
        )}
        <div className="row">
          <div className="col-12 mt-3">
            {steps.map((step, index) => {
              const StepComponent = step.component

              return (
                <StepComponent
                  key={step.name + index}
                  isActive={activeIndexState.activeIndex === index}
                  isModalOpen={show}
                  stepIndex={index}
                  activeIndexState={activeIndexState}
                  component={step.name.toLowerCase()}
                  syncedStateUpdate={syncedStateUpdate}
                  getUpdatedData={getUpdatedData}
                />
              )
            })}
          </div>
        </div>
      </Dialog>
      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
    </>
  )
}

/**
 * FinanceCalculatorTool - A class representing a tool for managing and displaying a financing calculator.
 *
 * @class FinanceCalculatorTool
 */
class FinanceCalculatorTool {
  /**
   * Creates an instance of FinanceCalculatorTool.
   *
   * @param {Object} options - The tool configuration options.
   * @param {Object} options.data - Initial data for the tool.
   * @param {Object} options.api - An API object for interactions.
   * @param {Object} options.config - Configuration settings for the tool.
   */
  constructor({ data, api, config }) {
    this.api = api
    this.config = config
    this.uniqueId = unique()

    const defaultData = {
      amount: {
        active: true,
        min: 1000,
        max: 100000,
        step: 500,
        default: 30000,
      },
      term: {
        active: true,
        min: 1,
        max: 7,
        step: 1,
        default: 6,
      },
      interest: {
        active: true,
        min: 0.5,
        max: 15,
        step: 0.5,
        default: 10,
      },
      frequency: {
        active: true,
        options: frequencyOptions.map((option) => option.value),
        default: '52',
        textColor: {
          colorType: 'light',
          customColor: '000000',
        },
      },
      repaymentsText: 'Your repayments could be',
      disclaimer:
        'This calculator has been developed as a guide only. It is for illustrative purposes and is based on the information you provided. No result from the use of this calculator should be considered a loan application or an offer of finance and it should not be relied upon to make a decision whether to apply for finance.',
      primaryColor: {
        colorType: 'primary',
        customColor: '000000',
      },
      secondaryColor: {
        colorType: 'dark',
        customColor: '000000',
      },
    }

    // Initialize data with provided data or defaultData
    this.data = Object.keys(data).length ? data : defaultData

    this.nodes = {
      holder: null,
    }
  }

  /**
   * A getter method for defining the toolbox configuration.
   * Defined title/icon shown in the "Add" toolbar.
   *
   * @static
   * @returns {Object} - Toolbox configuration with title and icon.
   */
  static get toolbox() {
    return {
      title: 'Finance Calculator',
      icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"></path></svg>`,
    }
  }

  /**
   * Renders the finance calculator tool's user interface.
   *
   * @returns {HTMLElement} - The root element containing the tool's UI.
   */
  render() {
    const rootNode = document.createElement('div')
    this.nodes.holder = rootNode

    // Callback function for updating data and saving configuration
    const onDataChange = (newData) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
    }

    const getUpdatedData = () => {
      return this.data
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedFinanceCalculatorComponent
        onDataChange={onDataChange}
        getUpdatedData={getUpdatedData}
        data={this.data}
        toolInfo={this.toolInfo}
        uniqueId={this.uniqueId}
      />
    )

    return this.nodes.holder
  }

  /** Create the settings panel for the block */
  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  /**
   * Saves the current tool data.
   *
   * @async
   * @returns {Object} - The current data object.
   */
  async save() {
    return this.data
  }
}

export default FinanceCalculatorTool
