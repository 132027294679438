import { default as React } from 'react'
import { createRoot } from 'react-dom/client'

const RenderedReviewsComponent = ({ onDataChange, data }) => (
  <div className="d-flex flex-column align-items-center border rounded shadow-sm p-3 mb-3">
    <h5 className="text-center">
      <i className="fa fa-star mr-2" aria-hidden="true"></i>
      Reviews Section
    </h5>
    <div className="form-group">
      <label htmlFor="variant-select">Display Option</label>
      <select
        className="form-control"
        id="variant-select"
        defaultValue={data.variant}
        onChange={(e) => onDataChange({ variant: e.target.value })}
      >
        <option value="simple">Simple</option>
        <option value="highlighted">Highlighted</option>
        <option value="full">Full</option>
      </select>
    </div>
  </div>
)

class ReviewsTool {
  static get toolbox() {
    return {
      title: 'Reviews',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
    </svg>
    `,
    }
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api
    this.readOnly = readOnly
    this.config = config

    let defaultValue = {
      variant: 'simple',
    }

    this.data = Object.keys(data).length ? data : defaultValue

    this.CSS = {
      wrapper: 'walkthrough-timeline',
    }

    this.nodes = {
      holder: null,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    rootNode.setAttribute('class', this.CSS.wrapper)
    this.nodes.holder = rootNode

    const onDataChange = (newData) => {
      this.data = {
        ...newData,
      }
      this.config.save()
    }

    const root = createRoot(rootNode)
    root.render(<RenderedReviewsComponent onDataChange={onDataChange} data={this.data} />)

    return this.nodes.holder
  }

  save(blockContent) {
    return this.data
  }
}

export default ReviewsTool
