import { default as React, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { ToolHeader } from '../common'
import { capitalize, renderEditSettingsButton, renderHiddenModalButton } from '../common/Utils'
import Dialog from '../common/Dialog'

import { unique } from '../../entries/utils'

const RenderedModelsComponent = ({ onDataChange, data, brands, toolInfo, uniqueId }) => {
  const [show, setShow] = useState(data.brand === '' || !data.brand ? true : false)
  const [selectedBrand, setSelectedBrand] = useState(data.brand)

  return (
    <>
      <div className="text-danger text-center">
        {selectedBrand === '' || !selectedBrand
          ? 'Please select a brand...'
          : `${capitalize(selectedBrand)} Models section preview is not yet available...`}
      </div>
      <Dialog title="Models" show={show} closeClickHandler={() => setShow(false)}>
        <ToolHeader {...toolInfo} />
        <div className="pt-3 border-top">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="brand-select">Brand Option</label>
                <select
                  className="form-control"
                  id="brand-select"
                  defaultValue={data.brand}
                  onChange={(e) => {
                    setSelectedBrand(e.target.value)
                    onDataChange({ brand: e.target.value })
                  }}
                >
                  <option value="">Select a brand</option>
                  {brands?.map((brand) => (
                    <option key={brand.id} value={`${brand.slug}`}>
                      {brand.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
    </>
  )
}

class ModelsTool {
  static get toolbox() {
    return {
      title: 'Models',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
      </svg>
      `,
    }
  }

  constructor({ data, config, api }) {
    this.api = api
    this.config = config
    this.uniqueId = unique()

    let defaultValue = {
      brand: '',
    }

    this.data = Object.keys(data).length ? data : defaultValue

    this.CSS = {
      wrapper: 'walkthrough-timeline',
    }

    this.nodes = {
      holder: null,
    }

    this.toolInfo = {
      heading: undefined,
      heading: 'Models Showcase',
      helpText:
        'Select a brand and show all the models associated with that manufacturer. The available options are based on OEM websites under your dealership.',
      hideToggle: true,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    rootNode.setAttribute('class', this.CSS.wrapper)
    this.nodes.holder = rootNode

    const onDataChange = (newData) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedModelsComponent
        onDataChange={onDataChange}
        data={this.data}
        brands={this.config?.brands}
        toolInfo={this.toolInfo}
        uniqueId={this.uniqueId}
      />
    )

    return this.nodes.holder
  }

  /** Create the settings panel for the block */
  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  save() {
    return this.data
  }
}

export default ModelsTool
