import { useState } from 'react'
import { TabView, TabPanel } from 'primereact/tabview'
import {
  ItemOption,
  LabeledInput,
  LabeledTextarea,
  LabeledFileInput,
  LabeledSelect,
  bootstrapColours,
} from '../../../common'
import { ColorPicker } from 'primereact/colorpicker'

export const CardEditor = ({
  index,
  item: initialItem,
  updateItem,
  deleteItem,
  moveUp,
  imageEndpoint,
}) => {
  const [edit, setEdit] = useState(true)

  // Fallback to ensure that each card has default colour property values for for cards tool instances before 6/5/24
  const item = {
    ...initialItem,
    headingColor: {
      colorType: initialItem.headingColor?.colorType ?? 'primary',
      customColor: initialItem.headingColor?.customColor ?? 'ffffff',
    },
    descriptionColor: {
      colorType: initialItem.descriptionColor?.colorType ?? 'secondary',
      customColor: initialItem.descriptionColor?.customColor ?? 'ffffff',
    },
    backgroundColor: {
      colorType: initialItem.backgroundColor?.colorType ?? 'custom',
      customColor: initialItem.backgroundColor?.customColor ?? 'ffffff',
    },
  }

  return (
    <ItemOption
      edit={edit}
      index={index}
      item={item}
      title="Card"
      hideBtnText
      setEdit={setEdit}
      moveUp={moveUp}
      deleteItem={deleteItem}
      hidePreview
    >
      {/* Form field Inputs */}
      <TabView panelContainerClassName="p-0">
        <TabPanel header="Content">
          <div className="row">
            <div className="col-12">
              <LabeledInput
                item={item}
                itemName="heading"
                label="Card Heading"
                updateItem={updateItem}
              />
            </div>
            <div className="col-12">
              <LabeledTextarea
                item={item}
                itemName="description"
                label="Card Text"
                updateItem={updateItem}
              />
            </div>
            <div className="col-12">
              <LabeledFileInput
                item={item}
                itemName="image"
                file={item.image}
                updateItem={updateItem}
                imageEndpoint={imageEndpoint}
              />
            </div>
            <div className="col-12">
              <LabeledInput item={item} itemName="href" label="Link URL" updateItem={updateItem} />
            </div>
            <div className="col-12">
              <LabeledInput
                item={item}
                itemName="linkText"
                label="Link Text"
                updateItem={updateItem}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Colours">
          {/* Heading colour */}
          <div className="row">
            <div className="col-12">
              <LabeledSelect
                item={item.headingColor}
                itemName="colorType"
                label="Heading Colour"
                customOnChange={(e) =>
                  updateItem({
                    ...item,
                    headingColor: {
                      ...item.headingColor,
                      colorType: e.target.value,
                    },
                  })
                }
                options={[...Array.from(bootstrapColours), 'custom']}
              />
            </div>
            {item.headingColor.colorType === 'custom' && (
              <div className="col-12 d-flex align-items-end mb-3">
                <ColorPicker
                  value={item.headingColor.customColor}
                  onChange={(e) =>
                    updateItem({
                      ...item,
                      headingColor: { ...item.headingColor, customColor: e.value },
                    })
                  }
                  appendTo="self"
                  pt={{
                    input: () => ({
                      style: {
                        height: 38,
                        width: 38,
                      },
                    }),
                  }}
                />
                <input
                  placeholder={'Enter a color hexcode'}
                  className="form-control ml-1"
                  value={item.headingColor.customColor}
                  onChange={(e) =>
                    updateItem({
                      ...item,
                      textColor: {
                        ...item.headingColor,
                        customColor: e.target.value,
                      },
                    })
                  }
                />
              </div>
            )}
          </div>
          {/* Description colour */}
          <div className="row">
            <div className="col-12">
              <LabeledSelect
                item={item.descriptionColor}
                itemName="colorType"
                label="Description Colour"
                customOnChange={(e) =>
                  updateItem({
                    ...item,
                    descriptionColor: {
                      ...item.descriptionColor,
                      colorType: e.target.value,
                    },
                  })
                }
                options={[...Array.from(bootstrapColours), 'custom']}
              />
            </div>

            {item.descriptionColor?.colorType === 'custom' && (
              <div className="col-12 d-flex align-items-end mb-3">
                <ColorPicker
                  value={item.descriptionColor.customColor}
                  onChange={(e) =>
                    updateItem({
                      ...item,
                      descriptionColor: { ...item.descriptionColor, customColor: e.value },
                    })
                  }
                  appendTo="self"
                  pt={{
                    input: () => ({
                      style: {
                        height: 38,
                        width: 38,
                      },
                    }),
                  }}
                />
                <input
                  placeholder={'Enter a color hexcode'}
                  className="form-control ml-1"
                  value={item.descriptionColor.customColor}
                  onChange={(e) =>
                    updateItem({
                      ...item,
                      descriptionColor: {
                        ...item.descriptionColor,
                        customColor: e.target.value,
                      },
                    })
                  }
                />
              </div>
            )}
          </div>
          {/* Background colour */}
          <div className="row">
            <div className="col-12">
              <LabeledSelect
                item={item.backgroundColor}
                itemName="colorType"
                label="Background Colour"
                customOnChange={(e) =>
                  updateItem({
                    ...item,
                    backgroundColor: {
                      ...item.backgroundColor,
                      colorType: e.target.value,
                    },
                  })
                }
                options={[...Array.from(bootstrapColours), 'custom']}
              />
            </div>
            {item.backgroundColor.colorType === 'custom' && (
              <div className="col-12 d-flex align-items-end mb-3">
                <ColorPicker
                  value={item.backgroundColor.customColor}
                  onChange={(e) =>
                    updateItem({
                      ...item,
                      backgroundColor: { ...item.backgroundColor, customColor: e.value },
                    })
                  }
                  appendTo="self"
                  pt={{
                    input: () => ({
                      style: {
                        height: 38,
                        width: 38,
                      },
                    }),
                  }}
                />
                <input
                  placeholder={'Enter a color hexcode'}
                  className="form-control ml-1"
                  value={item.backgroundColor.customColor}
                  onChange={(e) =>
                    updateItem({
                      ...item,
                      backgroundColor: {
                        ...item.backgroundColor,
                        customColor: e.target.value,
                      },
                    })
                  }
                />
              </div>
            )}
          </div>
        </TabPanel>
      </TabView>
    </ItemOption>
  )
}

export default CardEditor
