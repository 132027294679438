import { HTMLBlocks } from './blocks/htmlBlocks'
import ButtonsBlock from './blocks/ButtonsBlock'
import CarsBlock from './blocks/CarsBlock'
import FormBlock from './blocks/FormBlock'
import FormBuilderBlock from './blocks/FormBuilderBlock'

export const ResolveBlock = ({
  block,
  isInRow = false,
  enableTextContainer = true,
  locationOptions,
  isAlternatingColumns,
  ...props
}) => {
  switch (block.type) {
    case 'buttonsTool':
      return <ButtonsBlock block={block} isInRow={isInRow} />
    case 'cars':
      return <CarsBlock block={block} />
    case 'form':
      return <FormBlock block={block} />
    case 'formBuilder':
      return <FormBuilderBlock block={block} locationOptions={locationOptions} />
    default:
      const Element = HTMLBlocks[block.type]
      if (!Element) {
        return null
      }
      return (
        <Element
          block={block}
          isInRow={isInRow || !enableTextContainer}
          isAlternatingColumns={isAlternatingColumns}
        />
      )
  }
}

/** Render an array of Editor.js blocks */
export const BlockRenderer = ({ blocks, ...props }) => {
  // Leave this console.log, it's useful for checking blocks
  //console.log(blocks)
  return blocks.map((block, index) => (
    <ResolveBlock key={index} block={{ ...block, index }} {...props} />
  ))
}

const EditorJsRenderer = ({ data, ...props }) => {
  // Handle unpublished pages (i.e. no data available)
  if (!data?.blocks) {
    return null
  }

  return <BlockRenderer blocks={data.blocks} {...props} />
}

export default EditorJsRenderer
