import { default as React } from 'react'
import { FormGroup } from './FormGroup'
import { translateBootstrapColour } from './constants'
import InputLabel from './InputLabel'

export const LabeledSelect = ({
  controlled = true,
  item,
  itemName,
  label,
  options,
  updateItem,
  tooltip,
  customOnChange,
}) => {
  /** We can update the way some options are displayed to make it more user-friendly */
  const transformOptionDisplay = (option) => {
    return translateBootstrapColour(option)
  }

  return (
    <FormGroup>
      <InputLabel label={label} itemName={itemName} tooltip={tooltip} />
      <select
        id={itemName}
        className="form-control"
        value={controlled ? item[itemName] : undefined}
        defaultValue={!controlled ? item[itemName] : undefined}
        onChange={(e) =>
          customOnChange ? customOnChange(e) : updateItem({ ...item, [itemName]: e.target.value })
        }
      >
        {options.map((option, index) =>
          // Allow different label & value
          typeof option === 'object' ? (
            <option key={`${itemName}-option-${index}`} value={option.value}>
              {transformOptionDisplay(option.label)}
            </option>
          ) : (
            <option key={`${itemName}-option-${index}`} value={option}>
              {transformOptionDisplay(option)}
            </option>
          )
        )}
      </select>
    </FormGroup>
  )
}
