// EditorJS Components
import EditorJS from '@editorjs/editorjs'
import Table from '@editorjs/table'
import Header from '@editorjs/header'
import List from '@editorjs/list'
import ImageTool from '@editorjs/image'
import Paragraph from '@editorjs/paragraph'
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune'
import Embed from '@editorjs/embed'
import RawTool from '@editorjs/raw'

// Custom Tools
import {
  AlternatingColumnsTool,
  BrandsTool,
  BrandDescriptionTool,
  ButtonsTool,
  CardsTool,
  CarsTool,
  CloudinaryVideoTool,
  ColumnsTool,
  CustomComponentTool,
  FaqTool,
  FinanceCalculatorTool,
  FormTool,
  FormBuilderTool,
  HorizontalRuleTool,
  IconButtonsTool,
  ImageBannerTool,
  ImageSliderTool,
  LocationsTool,
  ModelColorsTool,
  ModelFeaturesTool,
  ModelsTool,
  ModelVariantsTool,
  ReviewsTool,
  SpecialOffersTool,
  TagsTool,
  TeamTool,
} from './tools'
import ImageBackgroundTool from './ImageBackgroundTool'

// Custom Tunes
import {
  BackgroundColorTune,
  ClassnameTune,
  ContainerTune,
  MarginTune,
  PaddingTune,
  ScreensizeTune,
  TextColorTune,
} from './tunes'

// Utils
import { ready } from '../entries/utils'
import { initialiseStyling } from './common/Utils'
import { fetchData, getTemplate } from './templates/templateUtils'
import {
  getSearchTemplateEditorTools,
  handleSearchOnChangeEvents,
  handleSearchOnReadyEvents,
} from './templates/Search/searchUtils'
import { handleTemplateRefresh } from './templates/Model/modelRefreshUtils'

const tunes = (saved) => {
  return {
    alignment: {
      class: AlignmentTuneTool,
      config: {
        default: 'left',
      },
    },
    classname: {
      class: ClassnameTune,
      config: {
        save: saved,
      },
    },
    container: {
      class: ContainerTune,
      config: {
        save: saved,
      },
    },
    backgroundColor: {
      class: BackgroundColorTune,
      config: {
        save: saved,
      },
    },
    textColor: {
      class: TextColorTune,
      config: {
        save: saved,
      },
    },
    margin: {
      class: MarginTune,
      config: {
        save: saved,
      },
    },
    padding: {
      class: PaddingTune,
      config: {
        save: saved,
      },
    },
    screensize: {
      class: ScreensizeTune,
      config: {
        save: saved,
      },
    },
  }
}

ready(async function () {
  // Added a 10ms timeout to allow render time for any react component using jseditor
  setTimeout(async () => {
    let input = document.getElementById('draft-blocks-json')
    let editor = document.getElementById('editorjs')

    if (editor) {
      let imageUrl = editor.getAttribute('imageUrl')
      // Convert to api fetch
      // Need new API, data should be same as _new_form.html.haml
      // getEditorInitData()
      const locationJSON = editor.getAttribute('locations')
      const locations = JSON.parse(locationJSON)
      const brandJSON = editor.getAttribute('brands')
      const brands = JSON.parse(brandJSON)
      const manufacturerId = editor.getAttribute('manufacturer_id')
      const modelSlug = editor.getAttribute('model_slug')
      const primaryLocationId = editor.getAttribute('primary_location_id')
      const websiteId = editor.getAttribute('website_id')
      const templateType = editor.getAttribute('template_type')

      // Set up template data object
      const templateData = {
        website_id: websiteId ?? undefined,
        type:
          // Handle existing model/manufacturer templates with default type
          templateType === 'default'
            ? modelSlug
              ? 'model'
              : manufacturerId
              ? 'manufacturer'
              : templateType // default
            : // Use template type if it is not default
              templateType,
        manufacturer: manufacturerId !== '' ? manufacturerId : undefined,
        model: modelSlug !== '' ? modelSlug : undefined,
      }

      if (input) {
        let data = {}
        if (input.value) {
          data = JSON.parse(input.value)

          // Only runs if it is a template page and data is empty
          if (
            templateData.type !== 'default' &&
            (Object.keys(data).length < 1 || data?.blocks?.length < 1)
          ) {
            const template = await getTemplate(templateData, brands)
            data.blocks = template
            input.value = JSON.stringify(data)
          }
        }

        // The ImageBackgroundTool is deprecated as per 28/03/24, we only allow for existing instances
        const hasImageBackground =
          data?.blocks?.some((block) => block?.type === 'imageBackground') ?? false

        const editor = new EditorJS({
          holder: 'editorjs',
          placeholder: 'Type your content here!',
          tools:
            templateData.type === 'search'
              ? getSearchTemplateEditorTools(tunes, saved, imageUrl, brands, templateData)
              : {
                  header: {
                    class: Header,
                    inlineToolbar: true,
                    tunes: [
                      'alignment',
                      'container',
                      'backgroundColor',
                      'textColor',
                      'margin',
                      'padding',
                      'classname',
                    ],
                  },
                  paragraph: {
                    class: Paragraph,
                    inlineToolbar: true,
                    tunes: [
                      'alignment',
                      'container',
                      'backgroundColor',
                      'textColor',
                      'margin',
                      'padding',
                      'classname',
                    ],
                    config: {
                      preserveBlank: true,
                    },
                  },
                  image: {
                    class: ImageTool,
                    tunes: ['container', 'margin', 'screensize', 'classname'],
                    config: {
                      endpoints: {
                        byFile: imageUrl,
                      },
                      captionPlaceholder: 'Image Alt (Recommended) OR Link URL (Optional)',
                    },
                  },
                  list: {
                    class: List,
                    inlineToolbar: true,
                    tunes: [
                      'alignment',
                      'container',
                      'backgroundColor',
                      'textColor',
                      'margin',
                      'padding',
                      'classname',
                    ],
                  },
                  table: {
                    class: Table,
                    inlineToolbar: true,
                    tunes: ['container', 'margin', 'padding', 'classname'],
                  },
                  horizontalRule: {
                    class: HorizontalRuleTool,
                    tunes: ['container', 'margin', 'padding', 'classname'],
                    config: {
                      save: saved,
                    },
                  },
                  embed: Embed,
                  cars: {
                    class: CarsTool,
                    config: {
                      save: saved,
                    },
                    tunes: ['backgroundColor', 'margin', 'padding', 'classname'],
                  },
                  offers: {
                    class: SpecialOffersTool,
                    config: {
                      save: saved,
                    },
                    tunes: ['backgroundColor', 'margin', 'padding', 'classname'],
                  },
                  form: {
                    class: FormTool,
                    config: {
                      save: saved,
                    },
                    tunes: ['backgroundColor', 'margin', 'padding', 'classname'],
                  },
                  formBuilder: {
                    class: FormBuilderTool,
                    config: {
                      locations,
                    },
                    tunes: ['backgroundColor', 'margin', 'padding', 'classname'],
                  },
                  buttonsTool: {
                    class: ButtonsTool,
                    config: {
                      save: saved,
                    },
                    inlineToolbar: true,
                    tunes: [
                      'alignment',
                      'container',
                      'backgroundColor',
                      'margin',
                      'padding',
                      'classname',
                    ],
                  },
                  tag: {
                    class: TagsTool,
                    config: {
                      save: saved,
                    },
                    tunes: [
                      'alignment',
                      'container',
                      'backgroundColor',
                      'margin',
                      'padding',
                      'classname',
                    ],
                  },
                  cardsTool: {
                    class: CardsTool,
                    config: {
                      imageUrl,
                      save: saved,
                    },
                    tunes: [
                      'alignment',
                      'container',
                      'backgroundColor',
                      'margin',
                      'padding',
                      'classname',
                    ],
                  },
                  faq: {
                    class: FaqTool,
                    config: {
                      save: saved,
                      tools: {
                        header: {
                          class: Header,
                          inlineToolbar: true,
                          tunes: ['alignment', 'container', 'textColor', 'margin', 'classname'],
                        },
                        paragraph: {
                          class: Paragraph,
                          inlineToolbar: true,
                          tunes: ['alignment', 'container', 'textColor', 'margin', 'classname'],
                          config: {
                            preserveBlank: true,
                          },
                        },
                        image: {
                          class: ImageTool,
                          tunes: ['container', 'margin', 'classname'],
                          config: {
                            endpoints: {
                              byFile: imageUrl,
                            },
                            captionPlaceholder: 'Link URL (Optional)',
                          },
                        },
                        list: {
                          class: List,
                          inlineToolbar: true,
                          tunes: ['alignment', 'container', 'textColor', 'margin', 'classname'],
                        },
                        buttonsTool: {
                          class: ButtonsTool,
                          tunes: ['alignment', 'container', 'margin', 'classname'],
                          config: {
                            save: saved,
                          },
                        },
                        table: Table,
                        ...tunes(saved),
                      },
                      save: saved,
                    },
                    tunes: ['container', 'margin', 'classname'],
                  },
                  locations: {
                    class: LocationsTool,
                    config: {
                      locations,
                      primaryLocationId,
                      save: saved,
                    },
                    tunes: ['margin', 'classname'],
                  },
                  reviews: {
                    class: ReviewsTool,
                    config: {
                      save: saved,
                    },
                    tunes: ['alignment', 'backgroundColor', 'margin', 'padding', 'classname'],
                  },
                  team: {
                    class: TeamTool,
                    config: {
                      save: saved,
                    },
                    tunes: ['container', 'margin', 'classname'],
                  },
                  raw: RawTool,
                  columns: {
                    class: ColumnsTool,
                    config: {
                      save: saved,
                      locations,
                      tools: {
                        header: {
                          class: Header,
                          inlineToolbar: true,
                          tunes: ['alignment', 'container', 'textColor', 'margin', 'classname'],
                        },
                        paragraph: {
                          class: Paragraph,
                          inlineToolbar: true,
                          tunes: ['alignment', 'container', 'textColor', 'margin', 'classname'],
                          config: {
                            preserveBlank: true,
                          },
                        },
                        image: {
                          class: ImageTool,
                          tunes: ['container', 'margin', 'classname'],
                          config: {
                            endpoints: {
                              byFile: imageUrl,
                            },
                            captionPlaceholder: 'Link URL (Optional)',
                          },
                        },
                        list: {
                          class: List,
                          inlineToolbar: true,
                          tunes: ['alignment', 'container', 'textColor', 'margin', 'classname'],
                        },
                        embed: Embed,
                        cars: {
                          class: CarsTool,
                          tunes: ['container', 'margin', 'classname'],
                          config: {
                            save: saved,
                          },
                        },
                        form: {
                          class: FormTool,
                          tunes: ['container', 'margin', 'classname'],
                          config: {
                            save: saved,
                          },
                        },
                        formBuilder: {
                          class: FormBuilderTool,
                          tunes: ['container', 'margin', 'classname'],
                          config: {
                            locations,
                            save: saved,
                          },
                          tunes: ['margin', 'classname'],
                        },
                        buttonsTool: {
                          class: ButtonsTool,
                          tunes: ['alignment', 'container', 'margin', 'classname'],
                          config: {
                            save: saved,
                          },
                        },
                        table: {
                          class: Table,
                          tunes: ['container', 'margin', 'classname'],
                        },
                        ...tunes(saved),
                      },
                    },
                    tunes: ['container', 'backgroundColor', 'margin', 'padding', 'classname'],
                  },
                  alternatingColumns: {
                    class: AlternatingColumnsTool,
                    config: {
                      save: saved,
                      tools: {
                        header: {
                          class: Header,
                          inlineToolbar: true,
                          tunes: ['alignment', 'container', 'textColor', 'margin', 'classname'],
                        },
                        paragraph: {
                          class: Paragraph,
                          inlineToolbar: true,
                          tunes: ['alignment', 'container', 'textColor', 'margin', 'classname'],
                          config: {
                            preserveBlank: true,
                          },
                        },
                        image: {
                          class: ImageTool,
                          config: {
                            endpoints: {
                              byFile: imageUrl,
                            },
                            captionPlaceholder: 'Link URL (Optional)',
                          },
                        },
                        list: {
                          class: List,
                          inlineToolbar: true,
                          tunes: ['alignment', 'container', 'textColor', 'margin', 'classname'],
                        },
                        embed: Embed,
                        buttonsTool: {
                          class: ButtonsTool,
                          tunes: ['alignment', 'container', 'margin', 'classname'],
                          config: {
                            save: saved,
                          },
                        },
                        table: {
                          class: Table,
                          tunes: ['container', 'margin', 'classname'],
                        },
                        ...tunes(saved),
                      },
                    },
                    tunes: ['container', 'margin', 'classname'],
                  },
                  imageBanner: {
                    class: ImageBannerTool,
                    config: {
                      save: saved,
                      brands: brands ?? [],
                      templateData,
                      imageUrl,
                      tools: {
                        header: {
                          class: Header,
                          inlineToolbar: true,
                          tunes: [
                            'alignment',
                            'container',
                            'textColor',
                            'margin',
                            'padding',
                            'classname',
                          ],
                        },
                        paragraph: {
                          class: Paragraph,
                          inlineToolbar: true,
                          tunes: [
                            'alignment',
                            'container',
                            'textColor',
                            'margin',
                            'padding',
                            'classname',
                          ],
                        },
                        buttonsTool: {
                          class: ButtonsTool,
                          inlineToolbar: true,
                          tunes: ['alignment', 'container', 'margin', 'classname'],
                          config: {
                            save: saved,
                          },
                        },
                        ...tunes(saved),
                      },
                    },
                    tunes: ['margin', 'screensize', 'classname'],
                  },
                  imageSlider: {
                    class: ImageSliderTool,
                    config: {
                      imageUrl,
                      save: saved,
                    },
                    tunes: ['container', 'margin', 'classname', 'backgroundColor'],
                  },
                  websiteBrands: {
                    class: BrandsTool,
                    config: {
                      brands: brands ?? [],
                      imageUrl,
                      save: saved,
                    },
                    tunes: [
                      'alignment',
                      'container',
                      'backgroundColor',
                      'margin',
                      'padding',
                      'classname',
                    ],
                  },
                  brandModels: {
                    class: ModelsTool,
                    config: {
                      brands: brands?.filter((b) => b?.make) ?? [],
                      save: saved,
                    },
                    tunes: [
                      'alignment',
                      'container',
                      'backgroundColor',
                      'margin',
                      'padding',
                      'classname',
                    ],
                  },
                  modelVariants: {
                    class: ModelVariantsTool,
                    config: {
                      brands: brands?.filter((b) => b?.make) ?? [],
                      save: saved,
                    },
                    tunes: ['alignment', 'container', 'margin', 'classname'],
                  },
                  modelColors: {
                    class: ModelColorsTool,
                    config: {
                      brands: brands?.filter((b) => b?.make) ?? [],
                      save: saved,
                    },
                    tunes: ['alignment', 'container', 'margin', 'classname'],
                  },
                  iconButtonsTool: {
                    class: IconButtonsTool,
                    config: {
                      save: saved,
                    },
                    inlineToolbar: true,
                    tunes: ['alignment', 'container', 'margin', 'classname'],
                  },
                  brandDescription: {
                    class: BrandDescriptionTool,
                    config: {
                      brands: brands ?? [],
                      imageUrl,
                      save: saved,
                    },
                    tunes: ['alignment', 'container', 'margin', 'classname'],
                  },
                  modelFeatures: {
                    class: ModelFeaturesTool,
                    config: {
                      imageUrl,
                      save: saved,
                    },
                    tunes: ['alignment', 'container', 'margin', 'classname'],
                  },
                  financeCalculator: {
                    class: FinanceCalculatorTool,
                    tunes: [
                      'container',
                      'backgroundColor',
                      'textColor',
                      'margin',
                      'padding',
                      'classname',
                    ],
                    config: {
                      save: saved,
                    },
                  },
                  video: {
                    class: CloudinaryVideoTool,
                    tunes: [
                      'alignment',
                      'container',
                      'backgroundColor',
                      'margin',
                      'padding',
                      'screensize',
                      'classname',
                    ],
                    config: {
                      save: saved,
                    },
                  },
                  customComponent: {
                    class: CustomComponentTool,
                    tunes: [
                      'alignment',
                      'container',
                      'backgroundColor',
                      'margin',
                      'padding',
                      'screensize',
                      'classname',
                    ],
                    config: {
                      // Eventually want to add websiteId, dealershipSlug to this
                      templateType: templateData.type,
                      save: saved,
                    },
                  },
                  ...(hasImageBackground && {
                    imageBackground: {
                      class: ImageBackgroundTool,
                      config: {
                        save: saved,
                        tools: {
                          header: {
                            class: Header,
                            inlineToolbar: true,
                            tunes: ['alignment', 'container', 'margin', 'classname'],
                          },
                          paragraph: {
                            class: Paragraph,
                            inlineToolbar: true,
                            tunes: ['alignment', 'container', 'margin', 'classname'],
                            config: {
                              preserveBlank: true,
                            },
                          },
                          image: {
                            class: ImageTool,
                            config: {
                              endpoints: {
                                byFile: imageUrl,
                              },
                              captionPlaceholder: 'Link URL (Optional)',
                            },
                          },
                          buttonsTool: {
                            class: ButtonsTool,
                            config: {
                              save: saved,
                            },
                            inlineToolbar: true,
                            tunes: ['alignment', 'container', 'margin', 'classname'],
                          },
                          ...tunes(saved),
                        },
                      },
                    },
                  }),

                  // Tunes
                  ...tunes(saved),
                },
          autofocus: false,
          /** To make the Editor have more screen-space, close the sidebar automatically */
          onReady: async () => {
            const sidebar = document.getElementById('sidebar')
            if (sidebar && !sidebar.classList.contains('collapsed')) {
              sidebar.classList.toggle('collapsed')
            }

            editor.configuration.data.blocks.map((block) => {
              const div = document.querySelector(`[data-id='${block.id}']`)
              return initialiseStyling(div, block.tunes)
            })

            // Add onReady event listeners for Search pages
            if (templateData.type === 'search') {
              handleSearchOnReadyEvents(editor)
            } else if (templateData.type === 'model') {
              // Fetch the updated model data
              const updatedModelData =
                 await fetchData('models', templateData.model).then((data) => {
                      return data
                    })
              
              // Add template refresh logic for model pages
              if (updatedModelData) {
                handleTemplateRefresh(updatedModelData, editor)
              }
            }

            // Force editor to save its state data on ready
            // This is to ensure templates are loaded correctly
            saved()
          },
          onChange: (api, event) => {
            // Add onChange event listeners for Search pages
            if (templateData.type === 'search') {
              handleSearchOnChangeEvents(api, event)
            }
            saved()
          },
          data: data,
        })

        // Save Example
        async function saved() {
          // Wait for the editor to be ready before allowing save function to be called
          await editor.isReady
          editor
            .save()
            .then((savedData) => {
              const event = new CustomEvent('editorSave', { detail: JSON.stringify(savedData) })
              window.dispatchEvent(event)
              input.value = JSON.stringify(savedData)
            })
            .catch((error) => {
              console.error('Saving error', error)
            })
        }
      }
    }
  }, 10)
})
