import { createRoot } from 'react-dom/client'
import { type CustomComponentDataProps, fetchComponentOptions } from './customComponentUtils'

import type { DefaultConfigProps, NodesProps, ToolboxProps } from '../../types/toolData.type'
import { useState } from 'react'
import { LabeledInput } from '../../common'

const RenderedFormComponent = ({ data, onDataChange, templateType }) => {
  const [state, setState] = useState(data)

  function setUniqueComponentId(value: string) {
    setState({ ...state, uniqueComponentId: value })
    onDataChange({ uniqueComponentId: value })
  }

  function setCustomComponentId(e: any) {
    setState({ ...state, customComponentId: e.target.value })
    onDataChange({ customComponentId: e.target.value })
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="border rounded shadow-sm p-3 bg-white">
            <div className="d-flex flex-column text-center mb-2 mx-0 mx-lg-5">
              <div>
                <p className="badge badge-warning mb-1">Warning</p>
              </div>
              <span className="small">
                This component is used to implement custom components that aren't customisable in
                the editor. All tune customisations are available, but each component may only be
                able to utilise specific ones. Please contact customer support if you are having any
                issues and need help.
              </span>
            </div>
            <div className="form-group">
              <label htmlFor="unique-component-id-select">Component</label>
              <select
                id="unique-component-id-select"
                className="form-control"
                defaultValue={data.uniqueComponentId ?? 'placeholder'}
                onChange={(e) => setUniqueComponentId(e.target.value)}
              >
                <option disabled value="placeholder">
                  Select a component
                </option>
                {fetchComponentOptions(templateType).map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
                <option value="custom">Custom</option>
              </select>
            </div>
            {state?.uniqueComponentId === 'custom' && (
              // @ts-ignore
              <LabeledInput
                controlled={false}
                item={state}
                itemName="customComponentId"
                label="Custom Component"
                placeholder="Enter the custom component ID"
                customOnChange={setCustomComponentId}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

class CustomComponentTool {
  private config: DefaultConfigProps
  private data: CustomComponentDataProps
  private CSS = {
    wrapper: 'walkthrough-timeline',
  }
  private nodes: NodesProps

  static get toolbox(): ToolboxProps {
    return {
      title: 'Custom Component',
      icon: '<i class="fa-regular fa-circle-exclamation"/>',
    }
  }

  constructor({ data, config }) {
    this.config = config

    let defaultData = {
      uniqueComponentId: undefined,
      customComponentId: undefined,
    }

    this.data = Object.keys(data).length ? data : defaultData

    this.CSS = {
      wrapper: 'walkthrough-timeline',
    }

    this.nodes = {
      holder: null,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    rootNode.setAttribute('class', this.CSS.wrapper)
    this.nodes.holder = rootNode

    const onDataChange = (newData: object) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedFormComponent
        onDataChange={onDataChange}
        data={this.data}
        templateType={this.config.templateType}
      />
    )

    return this.nodes.holder
  }

  save() {
    return this.data
  }
}

export default CustomComponentTool
