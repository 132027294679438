import { generateRandomId } from '../../common/Utils'
import { getPrimaryLocation, getErrorMessage } from '../templateUtils'
import { handleForm } from './tradeInUtils'

import { type WebsiteDetailsProps } from '../../types'

export function tradeInTemplate(websiteDetails: WebsiteDetailsProps) {
  // Error Handling
  if (websiteDetails === undefined) {
    return getErrorMessage()
  }

  const primaryLocation = getPrimaryLocation(websiteDetails)

  // * Autograb cannot be configured with FormBuilder for now
  const tradeInForm = handleForm(websiteDetails)

  return [
    {
      id: generateRandomId(10),
      type: 'imageBanner',
      data: {
        image: {
          type: 'default',
          existingCategory: 'default',
          existingFilters: {},
          image: {
            url: 'https://res.cloudinary.com/total-dealer/image/upload/v1645834405/td_next/tradein_w4lnrv',
          },
        },
        layout: {
          type: 'default',
          imageLayout: 'cover',
          customAspectRatio: {
            height: 1,
            width: 4,
          },
          customHeight: {
            mobileHeight: 250,
            desktopHeight: 450,
          },
        },
        overlay: {
          active: 'false',
          color: '#000000',
          opacity: 50,
        },
        content: {
          active: 'false',
          blocks: [
            {
              id: generateRandomId(10),
              type: 'header',
              data: {
                text: 'Image Banner',
                level: 2,
              },
              tunes: {
                alignment: {
                  alignment: 'center',
                },
                container: {
                  contain: true,
                },
                textColor: {
                  textColor: 'light',
                  customTextColor: '#000000',
                },
                margin: {
                  spacingTop: 5,
                  spacingBottom: 5,
                },
                padding: {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
                classname: {
                  className: '',
                },
              },
            },
          ],
        },
      },
      tunes: {
        margin: {
          spacingTop: 0,
          spacingBottom: 5,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: {
        text: 'Sell Your Car',
        level: 1,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: false,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: {
        text: `${primaryLocation?.address?.city} ${primaryLocation?.address?.state}`,
        level: 2,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: false,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'secondary',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 3,
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: `If you are looking to sell your vehicle or upgrade to a new vehicle, ask the team about our vehicle trade-in service. At ${websiteDetails.name}, we pride ourselves on offering our customers a hassle-free solution to selling your vehicle in ${primaryLocation?.address?.city}.`,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: true,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: 'We can assess your vehicle and present you with a competitive offer in no time at all, which you can choose to receive or use towards your next purchase with us.',
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: true,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: `To find out what your car is worth, fill out the enquiry form and a member of our team will be in touch. Alternatively, contact ${websiteDetails.name} to organise a vehicle valuation today.`,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: true,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'iconButtonsTool',
      data: {
        buttons: [
          {
            id: generateRandomId(10),
            icon: 'Service',
            text: 'No Obligations',
            href: '',
          },
          {
            id: generateRandomId(10),
            icon: 'Check',
            text: 'Free Appraisal',
            href: '',
          },
          {
            id: generateRandomId(10),
            icon: 'Finance',
            text: 'Competitive Offers',
            href: '',
          },
          {
            id: generateRandomId(10),
            icon: 'Sell Your Car',
            text: 'Sell Your Car Today',
            href: '',
          },
        ],
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: false,
        },
        margin: {
          spacingTop: 3,
          spacingBottom: 3,
        },
        classname: {
          className: '',
        },
      },
    },
    tradeInForm,
    {
      id: generateRandomId(10),
      type: 'locations',
      data: {
        filter: 'primary',
        manufacturer: false,
        subtype: false,
        filteredLocations: [],
        primaryLocationId: primaryLocation?.id,
      },
      tunes: {
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
  ]
}
