export const indexName = `Car_${process.env.RAILS_ENV}`

export const sortingOptions = [
    { value: `${indexName}:custom_rank:desc`, label: 'Rank by Popularity' },
    { value: `${indexName}:price:desc`, label: 'Price Highest First' },
    { value: `${indexName}:price:asc`, label: 'Price Lowest First' },
    { value: `${indexName}:created_at_timestamp:desc`, label: 'Newest First' },
    { value: `${indexName}:created_at_timestamp:asc`, label: 'Oldest First' },
    { value: `${indexName}:stocknum:asc`, label: 'Stock Number' },
    { value: `${indexName}:leads_count:desc`, label: 'Number of leads' },
    { value: `${indexName}:health:desc`, label: 'Car Health' },
    { value: `${indexName}:test_drives_count:desc`, label: 'No of Test Drives' },
]