import { generateRandomId } from '../../common/Utils'
import { getPrimaryLocation, getErrorMessage, getCarsViewerFilters } from '../templateUtils'

import { type WebsiteDetailsProps } from '../../types'

export function searchTemplate(websiteDetails: WebsiteDetailsProps) {
  if (websiteDetails === undefined) {
    return getErrorMessage()
  }

  const filters = getCarsViewerFilters()
  const primaryLocation = getPrimaryLocation(websiteDetails)

  return [
    {
      id: generateRandomId(10),
      type: 'imageBanner',
      data: {
        image: {
          type: 'custom',
          existingCategory: undefined,
          existingFilters: {
            manufacturer: undefined,
            model: undefined,
          },
          image: {
            url: undefined,
            previewUrl: undefined,
          },
        },
        layout: {
          type: 'fullSize',
          imageLayout: 'cover',
          customAspectRatio: {
            height: 1,
            width: 4,
          },
          customHeight: {
            mobileHeight: 250,
            desktopHeight: 450,
          },
        },
        overlay: {
          active: 'false',
          color: '#000000',
          opacity: 50,
        },
        content: {
          active: 'false',
          blocks: [
            {
              id: generateRandomId(10),
              type: 'header',
              data: {
                text: 'Image Banner',
                level: 2,
              },
              tunes: {
                alignment: {
                  alignment: 'center',
                },
                container: {
                  contain: true,
                },
                textColor: {
                  textColor: 'light',
                  customTextColor: '#000000',
                },
                margin: {
                  spacingTop: 5,
                  spacingBottom: 5,
                },
                padding: {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
                classname: {
                  className: '',
                },
              },
            },
          ],
        },
        searchBanner: true, // Custom property to identify this is a search banner
      },
      tunes: {
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: { text: `Cars for Sale in ${primaryLocation?.address.city}`, level: 1 },
      tunes: {
        alignment: { alignment: 'left' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'none', customTextColor: '#000000' },
        margin: { spacingTop: 0, spacingBottom: 0 },
        padding: { paddingTop: 0, paddingBottom: 0 },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: {
        text: `Browse all vehicles available at ${websiteDetails.name}`,
        level: 5,
      },
      tunes: {
        alignment: { alignment: 'left' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'none', customTextColor: '#000000' },
        margin: { spacingTop: 0, spacingBottom: 0 },
        padding: { paddingTop: 0, paddingBottom: 0 },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'cars',
      data: {
        searchState: {
          configure: {
            filters: filters,
          },
          menu: {},
          page: 1,
          hitsPerPage: 18,
          refinementList: {},
        },
        multiSelect: true,
        dateFilter: null,
        hideButton: true,
        hideSearch: true,
        buttonColor: 'light',
      },
      tunes: {
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        margin: { spacingTop: 0, spacingBottom: 0 },
        padding: { paddingTop: 0, paddingBottom: 0 },
        classname: { className: 'cars-component' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: { text: 'Optional disclaimer section...' },
      tunes: {
        alignment: { alignment: 'left' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'none', customTextColor: '#000000' },
        margin: { spacingTop: 0, spacingBottom: 0 },
        padding: { paddingTop: 0, paddingBottom: 0 },
        classname: { className: '' },
      },
    },
  ]
}
