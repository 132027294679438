import { generateRandomId } from '../../common/Utils'

// Constants
export const MIN_ROWS = 1
export const COL_KEYS = ['textCol', 'imgCol']

// Default text block values
export const defaultTextBlocks = [
  {
    id: generateRandomId(10),
    type: 'header',
    data: {
      text: 'Heading...',
      level: 2,
    },
    tunes: {
      alignment: {
        alignment: 'left',
      },
      classname: {
        className: '',
      },
    },
  },
  {
    id: generateRandomId(10),
    type: 'paragraph',
    data: {
      text: 'Text block...',
    },
    tunes: {
      alignment: {
        alignment: 'left',
      },
      classname: {
        className: '',
      },
    },
  },
]

export const defaultImageBlocks = []

export const defaultRowData = [
  {
    imgCol: {
      blocks: defaultImageBlocks,
    },
    textCol: {
      blocks: defaultTextBlocks,
    },
  },
]

/**
 * Updates the steps of the editor to account for the number of active columns.
 * @param {*} data The current data object.
 * @param {*} getUpdatedData Function to get the updated data object.
 * @param {*} setSteps Function to set the steps of the editor.
 * @param {*} RowEditor The editor component for the rows.
 */
export function refreshSteps(data, getUpdatedData, setSteps, RowEditor) {
  const tempSteps = []
  const rowNumber = data !== undefined ? getUpdatedData().columns?.length : 0

  // Add each row as a step
  for (let index = 0; index < rowNumber; index++) {
    tempSteps.push({ name: `Row ${index + 1}`, component: RowEditor })
  }

  setSteps(tempSteps)
}

/**
 * Handles the addition of a new row to the tool.
 * @param {*} getUpdatedData Function to get the updated data object.
 * @param {*} syncedStateUpdate Function to update the state of the editor.
 * @param {*} activeIndexState The current active index state.
 * @param {*} setActiveIndexState Function to set the active index of the editor.
 * @param {*} setSteps Function to set the steps of the editor.
 * @param {*} RowEditor The editor component for the rows.
 */
export function handleAddRow(
  getUpdatedData,
  syncedStateUpdate,
  activeIndexState,
  setActiveIndexState,
  setSteps,
  RowEditor
) {
  const updatedRows = getUpdatedData().columns
  updatedRows.push({
    imgCol: {
      blocks: defaultImageBlocks,
    },
    textCol: {
      blocks: defaultTextBlocks,
    },
  })

  syncedStateUpdate({
    ...getUpdatedData(),
    columns: updatedRows,
  })
  setActiveIndexState({
    activeIndex: updatedRows?.length - 1,
    previousActiveIndex: activeIndexState.activeIndex,
  })

  refreshSteps(getUpdatedData(), getUpdatedData, setSteps, RowEditor)
}

/**
 * Handles the deletion of a row from the tool.
 * @param {*} index The index of the row to delete.
 * @param {*} getUpdatedData Function to get the updated data object.
 * @param {*} syncedStateUpdate Function to update the state of the editor.
 * @param {*} setActiveIndexState Function to set the active index of the editor.
 * @param {*} data The current data object.
 * @param {*} setSteps Function to set the steps of the editor.
 * @param {*} RowEditor The editor component for the rows.
 */
export function handleDeleteRow(
  index,
  getUpdatedData,
  syncedStateUpdate,
  setActiveIndexState,
  data,
  setSteps,
  RowEditor
) {
  // Confirm delete action
  if (
    window.confirm(
      'Are you sure you want to delete this row? This action will delete all data within both columns in this row.'
    )
  ) {
    const updatedRows = []
    getUpdatedData().columns.map((row, rowIndex) => {
      if (rowIndex !== index) {
        updatedRows.push(row)
      }
    })

    syncedStateUpdate({
      ...getUpdatedData(),
      columns: updatedRows,
    })
    setActiveIndexState({
      activeIndex: index === 0 ? 0 : index - 1,
      previousActiveIndex: undefined,
    })

    refreshSteps(data, getUpdatedData, setSteps, RowEditor)
  }
}
