// EditorJS Components
import Table from '@editorjs/table'
import Header from '@editorjs/header'
import List from '@editorjs/list'
import ImageTool from '@editorjs/image'
import Paragraph from '@editorjs/paragraph'

// Custom Tools
import { ButtonsTool, CarsTool, HorizontalRuleTool, ImageBannerTool } from '../../tools'
import { getCarsViewerFilters } from '../templateUtils'

/**
 * Gets the available editor tools for the Search template
 *
 * @param tunes - a function that returns the tunes object
 * @param saved - a function that is called when the editor is saved
 * @param imageUrl - the image upload url
 * @returns An object containing the available editor tools
 */
export function getSearchTemplateEditorTools(
  tunes: (saved: () => void) => object,
  saved: () => void,
  imageUrl: string,
  brands: any[],
  templateData: any
) {
  return {
    header: {
      class: Header,
      inlineToolbar: false,
      tunes: [
        'alignment',
        'container',
        'backgroundColor',
        'textColor',
        'margin',
        'padding',
        'classname',
      ],
    },
    paragraph: {
      class: Paragraph,
      inlineToolbar: true,
      tunes: [
        'alignment',
        'container',
        'backgroundColor',
        'textColor',
        'margin',
        'padding',
        'classname',
      ],
      config: {
        preserveBlank: true,
      },
    },
    image: {
      class: ImageTool,
      tunes: ['container', 'margin', 'screensize', 'classname'],
      config: {
        endpoints: {
          byFile: imageUrl,
        },
        captionPlaceholder: 'Image Alt (Recommended) OR Link URL (Optional)',
      },
    },
    imageBanner: {
      class: ImageBannerTool,
      config: {
        save: saved,
        brands: brands ?? [],
        templateData,
        imageUrl,
        tools: {
          header: {
            class: Header,
            inlineToolbar: true,
            tunes: ['alignment', 'container', 'textColor', 'margin', 'padding', 'classname'],
          },
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
            tunes: ['alignment', 'container', 'textColor', 'margin', 'padding', 'classname'],
          },
          buttonsTool: {
            class: ButtonsTool,
            inlineToolbar: true,
            tunes: ['alignment', 'container', 'margin', 'classname'],
            config: {
              save: saved,
            },
          },
          ...tunes(saved),
        },
      },
      tunes: ['margin', 'screensize', 'classname'],
    },
    list: {
      class: List,
      inlineToolbar: true,
      tunes: ['container', 'backgroundColor', 'textColor', 'margin', 'padding', 'classname'],
    },
    table: {
      class: Table,
      inlineToolbar: true,
      tunes: ['container', 'margin', 'padding', 'classname'],
    },
    cars: {
      class: CarsTool,
      config: {
        templateType: 'search',
        save: saved,
      },
      tunes: [],
    },
    buttonsTool: {
      class: ButtonsTool,
      config: {
        save: saved,
      },
      inlineToolbar: true,
      tunes: ['alignment', 'container', 'backgroundColor', 'margin', 'padding', 'classname'],
    },
    horizontalRule: {
      class: HorizontalRuleTool,
      tunes: ['container', 'margin', 'padding', 'classname'],
      config: {
        save: saved,
      },
    },
    ...tunes(saved),
  }
}

/**
 * Handles the enter action for the heading blocks
 * @param editor - the editor instance
 */
function handleEnterAction(editor: any) {
  // Enter action moves content to next block if cursor is in the start/middle of the block
  // This logic is to prevent the deletion of the existing block content
  const previouslyActiveBlockContent = editor.blocks.getBlockByIndex(
    editor.blocks.getCurrentBlockIndex() - 1
  )
  // If the previously active block is not empty, delete the active block
  // If the previously active block is empty, delete the previously active block
  editor.blocks.delete(
    !previouslyActiveBlockContent.isEmpty
      ? editor.blocks.getCurrentBlockIndex()
      : editor.blocks.getCurrentBlockIndex() - 1
  )
}

/**
 * Handles event changes for the blocks
 * @param api - the editor API
 * @param event - the event that triggered the Editor's onChange event
 * @returns An array of alerts to display to the user
 */
function handleBlockEventChanges(api: any, event: any) {
  const alertList = []

  // If the main heading or subheading blocks are empty, undo the action
  if (
    event.type === 'block-changed' &&
    event.detail.target.isEmpty &&
    event.detail.target.name === 'header'
  ) {
    alertList.push('Search pages must have a heading and subheading. These blocks cannot be empty.')
    api.blocks.update(
      api.blocks.getBlockByIndex(event.detail.index).id,
      event.detail.index === 0
        ? {
            text: 'Heading',
            level: 1,
          }
        : { text: 'Subheading', level: 5 }
    )

    // If the main heading or subheading blocks are removed, re-add them
  } else if (event.type === 'block-removed' && event.detail.target.name === 'header') {
    alertList.push(
      'Search pages must have a heading and subheading. These blocks cannot be deleted.'
    )
    api.blocks.insert(
      'header',
      {
        text: event.detail.index === 0 ? 'Heading' : 'Subheading',
        level: event.detail.index === 0 ? 1 : 5,
      },
      {},
      event.detail.index
    )
  } else if (event.type === 'block-removed' && event.detail.target.name === 'cars') {
    alertList.push('Search pages must have a cars component. This block cannot be deleted.')
    api.blocks.insert(
      'cars',
      {
        searchState: {
          configure: {
            filters: getCarsViewerFilters(),
          },
          menu: {},
          page: 1,
          hitsPerPage: 18,
          refinementList: {},
        },
        multiSelect: true,
        dateFilter: null,
        hideButton: true,
        hideSearch: true,
        buttonColor: 'light',
      },
      {}
    )
  }

  return alertList
}

/**
 * Handles the search on ready events
 * @param editor - the editor instance
 */
export function handleSearchOnReadyEvents(editor: any) {
  // Disable Enter for first 3 blocks
  editor.configuration.data.blocks.splice(0, 3).map((block: { id: string }) => {
    const div = document.querySelector(`[data-id='${block.id}']`)
    // Add custom event listeners to the embedded editor blocks
    editor.listeners.on(div, 'keydown', (e: any) => {
      const key = e.which || e.keyCode || e.charCode
      if (key === 13) {
        handleEnterAction(editor)
      }
    })
  })

  // Always hide CarsTool button
  const carsToolButtons = Array.from(
    document.querySelectorAll('.ce-popover-item[data-item-name="cars"]')
  ) as HTMLElement[]
  carsToolButtons.forEach((button) => (button.style.display = 'none'))
}

/**
 * Handles the search on change events
 * @param api - the editor API
 * @param event - the event that triggered the function
 */
export function handleSearchOnChangeEvents(api: any, event: any) {
  const tuneMenuButton = document.querySelector('.ce-toolbar__settings-btn')
  const addMenuButton = document.querySelector('.ce-toolbar__plus')

  // Handle Tune menu settings
  tuneMenuButton.addEventListener('click', () => {
    const buttons = Array.from(
      document.getElementsByClassName('ce-popover__items')
    ) as HTMLElement[]
    const moveUpButtons = Array.from(
      document.querySelectorAll('.ce-popover-item[data-item-name="move-up"]')
    ) as HTMLElement[]

    // Hide/Show buttons based on block index
    if (api.blocks.getCurrentBlockIndex() < 4) {
      buttons.forEach((button) => (button.style.display = 'none'))
    } else {
      buttons.forEach((button) => (button.style.display = 'block'))
    }
    // Hide Move Up button for 5th block
    if (api.blocks.getCurrentBlockIndex() === 4) {
      moveUpButtons.forEach((button) => (button.style.display = 'none'))
    } else {
      moveUpButtons.forEach((button) => (button.style.display = 'flex'))
    }
  })

  // Handle Add menu settings
  addMenuButton.addEventListener('click', () => {
    const buttons = Array.from(
      document.getElementsByClassName('ce-popover__items')
    ) as HTMLElement[]

    if (api.blocks.getCurrentBlockIndex() < 4) {
      buttons.forEach((button) => (button.style.display = 'none'))
    } else {
      buttons.forEach((button) => (button.style.display = 'block'))
    }
  })

  // Force close toolbar on block move (to prevent block from being moved to the wrong position)
  if (event.type === 'block-moved') {
    api.toolbar.close()
  }

  // Handle onChange events
  // For multiple events, check if the event is an array
  if (Array.isArray(event)) {
    // Track alerts for multiple events
    const alertList = []

    // Handle each event individually
    event.forEach((e) => {
      // Remove empty block that is added when a block is removed (only apply to the first 4 blocks)
      if (e.type === 'block-added' && e.detail.index < 4 && e.detail.target.name === 'paragraph') {
        for (let i = 0; i < 4; i++) {
          if (
            api.blocks.getBlockByIndex(i).name === 'paragraph' &&
            api.blocks.getBlockByIndex(i).isEmpty
          ) {
            api.blocks.delete(i)
          }
        }
      } else if (
        // Handle block changes for the first 4 blocks
        (e.type === 'block-removed' || e.type === 'block-changed') &&
        e.detail.index < 4 &&
        (e.detail.target.name === 'header' || e.detail.target.name === 'cars')
      ) {
        alertList.push(...handleBlockEventChanges(api, e))
      }
    })

    // Display alerts if there are any issues with the changes
    if (alertList.length > 0) {
      alert(
        'Sorry, there were the following issues with your changes:\n\n' +
          [...Array.from(new Set(alertList))].join('\n\n')
      )
      // Make sure the cars block is always in the correct position after any major events
      if (api.blocks.getBlockByIndex(4).name === 'cars') {
        api.blocks.move(4, 3)
      }
    }

    // For single events, handle the event
  } else {
    const alertList = handleBlockEventChanges(api, event)
    // Display alerts if there are any issues with the changes
    if (alertList.length > 0) {
      alert('Sorry, there were the following issues with your changes:\n\n' + alertList.join('\n'))
    }
    // Make sure the cars block is always in the correct position after any major events
    if (api.blocks.getBlockByIndex(4).name === 'cars') {
      api.blocks.move(4, 3)
    }
  }
}
