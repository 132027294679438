import { useState, useEffect } from 'react'
import CarouselWrapper from '../../common/CarouselWrapper'

const ModelColor = ({ modelVariant, selectedIndex }) => (
  <div className="row justify-content-center">
    <div className="col-lg-5">
      <img
        src={modelVariant[selectedIndex]?.photo_url}
        alt={modelVariant[selectedIndex]?.name}
        className="w-100"
        style={{ objectFit: 'contain' }}
      />
      <h4 className="text-center mt-1">{modelVariant[selectedIndex]?.name}</h4>
    </div>
  </div>
)

const ColourCircle = ({ image, index, selectedIndex, setSelectedIndex }) => (
  <button
    className="w-100 h-100 overflow-hidden"
    onClick={() => setSelectedIndex(index)}
    style={{
      transform: index === selectedIndex ? 'scale(1.2)' : 'scale(1)',
      border: index === selectedIndex ? '2px solid var(--primary)' : 'none',
      borderRadius: '50%',
      boxShadow: '0 0 3px rgba(0, 0, 0, 0.3)',
    }}
  >
    <img
      src={image}
      alt="Image Thumbnail"
      className="w-100"
      style={{ objectFit: 'cover', objectPosition: 'center', scale: '1.8' }}
    />
  </button>
)

const ColourButton = ({ colour, index, selectedIndex, setSelectedIndex }) => (
  <div
    key={colour.name + ' option'}
    className="d-flex justify-content-center align-items-center position-relative"
    style={{
      margin: '0.5rem',
      width: '3rem',
      height: '3rem',
      backgroundColor: 'transparent',
    }}
  >
    <ColourCircle
      image={colour.photo_url}
      index={index}
      selectedIndex={selectedIndex}
      setSelectedIndex={setSelectedIndex}
    />
  </div>
)

const ModelColorTabs = ({
  modelVariants,
  modelVariantColors,
  selectedVariant,
  setSelectedVariant,
  selectedIndex,
  setSelectedIndex,
  children,
}) => (
  <>
    <div className="row">
      <div className="col-12 d-flex justify-content-center">
        {modelVariants.map((variant, index) => (
          <button
            key={`Variant-${index}`}
            className={
              'btn btn-sm mx-1 my-1 ' +
              (variant === selectedVariant ? 'btn-primary' : 'btn-outline-primary')
            }
            onClick={() => setSelectedVariant(variant)}
          >
            <span>{variant}</span>
          </button>
        ))}
      </div>
    </div>
    {children}
    <div className="row">
      <div className="col-12 d-flex justify-content-center">
        {modelVariantColors.map((color, index) => (
          <ColourButton
            key={`ColourButton-${index}`}
            colour={color}
            index={index}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        ))}
      </div>
    </div>
  </>
)

const ModelColorsPreview = ({ selectedModelData, generatedPreview }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [modelVariants, setModelVariants] = useState([])
  const [selectedVariant, setSelectedVariant] = useState(undefined)
  const [modelVariantColors, setModelVariantColors] = useState([])

  useEffect(() => {
    if (!selectedModelData?.colours?.length) {
      return
    }

    // Create an empty Set to store unique model_variant_name values
    const uniqueModelVariants = new Set()

    // Iterate through the data and add each model_variant_name to the Set
    selectedModelData.colours.forEach((item) => {
      if (item.model_variant_name !== null && item.model_variant_name !== undefined) {
        uniqueModelVariants.add(item.model_variant_name)
      }
    })

    // Convert the Set to an array to get the unique values
    const uniqueModelVariantsArray = Array.from(uniqueModelVariants)

    if (uniqueModelVariantsArray.length > 0) {
      const filteredModelVariants = selectedModelData.colours.filter(
        (colour) => colour.model_variant_name === uniqueModelVariantsArray[0]
      )

      setSelectedIndex(0)
      setModelVariants(uniqueModelVariantsArray)
      setSelectedVariant(uniqueModelVariantsArray[0])
      setModelVariantColors(filteredModelVariants)
    } else {
      setSelectedIndex(0)
      setModelVariants([])
      setSelectedVariant(undefined)
      setModelVariantColors([])
    }
  }, [selectedModelData])

  useEffect(() => {
    if (selectedVariant) {
      const filteredModelVariants = selectedModelData?.colours?.filter(
        (colour) => colour.model_variant_name === selectedVariant
      )
      setModelVariantColors(filteredModelVariants)
      setSelectedIndex(0)
    }
  }, [selectedVariant])

  return selectedModelData && selectedModelData.colours.length > 0 ? (
    <div className="mt-3 container">
      <h2 className="text-center">Colour Options</h2>
      <ModelColorTabs
        modelVariants={modelVariants}
        modelVariantColors={modelVariantColors}
        selectedVariant={selectedVariant}
        setSelectedVariant={setSelectedVariant}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
      >
        <CarouselWrapper
          activeIndex={selectedIndex}
          setActiveIndex={setSelectedIndex}
          hideButtons={modelVariantColors?.length < 2}
          max={modelVariantColors?.length - 1}
          loop={false}
        >
          <ModelColor
            modelVariant={modelVariantColors?.filter(
              (variant) => variant.model_variant_name === selectedVariant
            )}
            selectedIndex={selectedIndex}
          />
        </CarouselWrapper>
      </ModelColorTabs>
      <span className="mt-3 text-center">
        *Images are for illustrative purpose only. Preproduction vehicles shown. Local specification
        may vary.
      </span>
    </div>
  ) : (
    <span className="text-danger">
      {!selectedModelData
        ? 'Please select a model and generate a preview...'
        : generatedPreview
        ? 'Sorry, there is no color data for this model...'
        : 'Your selections have changed, please generate a new preview...'}
    </span>
  )
}

export default ModelColorsPreview
