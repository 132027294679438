import { default as React } from 'react'
import { useState } from 'react'
import { FormGroup } from './FormGroup'
import InputLabel from './InputLabel'

export const LabeledFileInput = ({
  item,
  itemName,
  file,
  label,
  updateItem,
  accept,
  imageEndpoint,
  maxImageWidth,
  maxImageHeight,
  tooltip,
}) => {
  const [previewUrl, setPreviewUrl] = useState(file?.url || '')
  const [loading, setLoading] = useState(false)

  const imgState = item[itemName] // {file:File; url:string} // undefined

  const handleFileChange = (e) => {
    const fileList = e.target.files
    if (fileList) {
      const blobUrl = URL.createObjectURL(fileList[0])
      // Do not overwrite a Cloudinary url with a blob URL...
      const updatedUrl = imgState ? imgState?.url : blobUrl
      console.log('Updated file state: ', { file: fileList[0], url: updatedUrl })
      updateItem({ ...item, [itemName]: { file: fileList[0], url: updatedUrl } })
      setPreviewUrl(blobUrl)
      upload(fileList[0])
    }
  }

  const upload = async (file) => {
    if (file) {
      setLoading(true)
      const cloudinaryResponse = await new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('image', file)

        const xhr = new XMLHttpRequest()
        xhr.open('POST', imageEndpoint)
        xhr.onreadystatechange = function () {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
              // File was uploaded successfully
              const response = JSON.parse(xhr.responseText)
              const previewUrl = response.file.url
              resolve(previewUrl)
            } else {
              // Handle error
              reject(new Error('Error uploading file: ' + xhr.status))
            }
          }
        }
        xhr.send(formData)
      })
      setLoading(false)
      console.log('Cloudinary response: ', cloudinaryResponse)
      if (cloudinaryResponse) {
        updateItem({ ...item, [itemName]: { file, url: cloudinaryResponse } })
        alert('Image successfully uploaded to Cloudinary: ' + cloudinaryResponse)
      } else {
        alert('An error occured when uploading the image')
      }
    } else {
      alert('There is no file to upload')
    }
  }

  const deleteImage = () => {
    updateItem({ ...item, [itemName]: { file: null, url: null } })
    setPreviewUrl('')
  }

  return (
    <FormGroup>
      <InputLabel label={label} itemName={itemName} tooltip={tooltip} />
      <input
        id={itemName}
        className="form-control"
        type="file"
        accept={accept}
        onChange={handleFileChange}
      />
      <div
        className="d-flex justify-content-center align-items-center mt-2 mb-1 mx-auto"
        style={{ maxWidth: maxImageWidth ?? undefined, maxHeight: maxImageHeight ?? undefined }}
      >
        {previewUrl && (
          <img
            className={maxImageHeight ? '' : 'w-100'}
            src={previewUrl}
            alt="uploaded file"
            style={{ objectFit: 'contain' }}
          />
        )}
      </div>

      {loading && (
        <span className={`my-2 btn btn-sm btn-outline-success`} style={{ borderRadius: '16px' }}>
          Uploading File...
        </span>
      )}
      {!loading && imgState?.url && (
        <button
          className={`my-2 btn btn-sm btn-outline-danger`}
          style={{ borderRadius: '16px' }}
          onClick={deleteImage}
        >
          Delete Image
        </button>
      )}
    </FormGroup>
  )
}
