import { generateRandomId } from '../../common/Utils'
import { getPrimaryLocation, getErrorMessage } from '../templateUtils'
import { getAboutUsBlocks } from './aboutUtils'

import { type WebsiteDetailsProps } from '../../types'

export async function aboutTemplate(websiteDetails: WebsiteDetailsProps) {
  if (websiteDetails === undefined) {
    return getErrorMessage()
  }

  const primaryLocation = getPrimaryLocation(websiteDetails)

  // * This only works for Manufacturer websites
  const existingAboutUsTemplate = websiteDetails.manufacturer_id
    ? await getAboutUsBlocks(websiteDetails)
    : undefined

  return existingAboutUsTemplate
    ? existingAboutUsTemplate.blocks
    : [
        {
          id: generateRandomId(10),
          type: 'imageBanner',
          data: {
            image: {
              type: 'custom',
              existingCategory: 'default',
              existingFilters: {},
              image: {
                url: 'https://res.cloudinary.com/total-dealer/image/upload/v1712544519/td_dashboard/about',
              },
            },
            layout: {
              type: 'default',
              imageLayout: 'cover',
              customAspectRatio: {
                height: 1,
                width: 4,
              },
              customHeight: {
                mobileHeight: 250,
                desktopHeight: 450,
              },
            },
            overlay: {
              active: 'false',
              color: '#000000',
              opacity: 50,
            },
            content: {
              active: 'false',
              blocks: [
                {
                  id: generateRandomId(10),
                  type: 'header',
                  data: {
                    text: 'Image Banner',
                    level: 2,
                  },
                  tunes: {
                    alignment: {
                      alignment: 'center',
                    },
                    container: {
                      contain: true,
                    },
                    textColor: {
                      textColor: 'light',
                      customTextColor: '#000000',
                    },
                    margin: {
                      spacingTop: 5,
                      spacingBottom: 5,
                    },
                    padding: {
                      paddingTop: 0,
                      paddingBottom: 0,
                    },
                    classname: {
                      className: '',
                    },
                  },
                },
              ],
            },
          },
          tunes: {
            margin: {
              spacingTop: 0,
              spacingBottom: 5,
            },
            classname: {
              className: '',
            },
          },
        },
        {
          id: generateRandomId(10),
          type: 'header',
          data: {
            text: 'About Us',
            level: 1,
          },
          tunes: {
            alignment: {
              alignment: 'center',
            },
            container: {
              contain: false,
            },
            backgroundColor: {
              backgroundColor: 'none',
              customBackgroundColor: '#000000',
            },
            textColor: {
              textColor: 'none',
              customTextColor: '#000000',
            },
            margin: {
              spacingTop: 5,
              spacingBottom: 0,
            },
            padding: {
              paddingTop: 0,
              paddingBottom: 0,
            },
            classname: {
              className: '',
            },
          },
        },
        {
          id: generateRandomId(10),
          type: 'header',
          data: {
            text: `${primaryLocation?.address?.city} ${primaryLocation?.address?.state}`,
            level: 2,
          },
          tunes: {
            alignment: {
              alignment: 'center',
            },
            container: {
              contain: false,
            },
            backgroundColor: {
              backgroundColor: 'none',
              customBackgroundColor: '#000000',
            },
            textColor: {
              textColor: 'secondary',
              customTextColor: '#000000',
            },
            margin: {
              spacingTop: 0,
              spacingBottom: 3,
            },
            padding: {
              paddingTop: 0,
              paddingBottom: 0,
            },
            classname: {
              className: '',
            },
          },
        },
        {
          id: generateRandomId(10),
          type: 'paragraph',
          data: {
            text: `Experience the difference at ${websiteDetails.name}. Whether you're in search of your next vehicle or need dependable service, we're here to ensure your automotive needs are met with professionalism and care. Reach out to us or come by our showroom to begin your journey with ${websiteDetails.name}.`,
          },
          tunes: {
            alignment: {
              alignment: 'center',
            },
            container: {
              contain: true,
            },
            backgroundColor: {
              backgroundColor: 'none',
              customBackgroundColor: '#000000',
            },
            textColor: {
              textColor: 'none',
              customTextColor: '#000000',
            },
            margin: {
              spacingTop: 0,
              spacingBottom: 0,
            },
            padding: {
              paddingTop: 0,
              paddingBottom: 0,
            },
            classname: {
              className: '',
            },
          },
        },
        {
          id: generateRandomId(10),
          type: 'paragraph',
          data: {
            text: `Our passionate team is dedicated to providing a transparent, straightforward buying experience. We're not just about selling cars; we're about creating lasting relationships with our customers, ensuring each person receives personalised service and support.`,
          },
          tunes: {
            alignment: {
              alignment: 'center',
            },
            container: {
              contain: true,
            },
            backgroundColor: {
              backgroundColor: 'none',
              customBackgroundColor: '#000000',
            },
            textColor: {
              textColor: 'none',
              customTextColor: '#000000',
            },
            margin: {
              spacingTop: 0,
              spacingBottom: 0,
            },
            padding: {
              paddingTop: 0,
              paddingBottom: 0,
            },
            classname: {
              className: '',
            },
          },
        },
        {
          id: generateRandomId(10),
          type: 'header',
          data: {
            text: 'Why Choose Us?',
            level: 3,
          },
          tunes: {
            alignment: {
              alignment: 'center',
            },
            container: {
              contain: false,
            },
            backgroundColor: {
              backgroundColor: 'light',
              customBackgroundColor: '#000000',
            },
            textColor: {
              textColor: 'none',
              customTextColor: '#000000',
            },
            margin: {
              spacingTop: 3,
              spacingBottom: 0,
            },
            padding: {
              paddingTop: 5,
              paddingBottom: 2,
            },
            classname: {
              className: '',
            },
          },
        },
        {
          id: generateRandomId(10),
          type: 'cardsTool',
          data: {
            activated: true,
            isCarousel: false,
            cards: [
              {
                id: generateRandomId(10),
                heading: '',
                description: 'A diverse range of new and pre-owned vehicles to suit any preference',
                linkText: '',
                href: '',
                image: {
                  file: {},
                  url: 'https://res.cloudinary.com/total-dealer/image/upload/v1/development/67qp2ucyptupwdxbpoth3aax6ugn.jpg',
                },
              },
              {
                id: generateRandomId(10),
                heading: '',
                description: 'A team committed to your satisfaction and tailored service',
                linkText: '',
                href: '',
                image: {
                  file: {},
                  url: 'https://res.cloudinary.com/total-dealer/image/upload/v1/development/4jv9c56l9v0fn9j6qtfysuik5m3i.png',
                },
              },
              {
                id: generateRandomId(10),
                heading: '',
                description: 'Exceptional maintenance and repair services by expert technicians',
                linkText: '',
                href: '',
                image: {
                  file: {},
                  url: 'https://res.cloudinary.com/total-dealer/image/upload/v1/development/tci9cqger0xqe5yo556580p71g34.png',
                },
              },
              {
                id: generateRandomId(10),
                heading: '',
                description:
                  'A strong commitment to our communities and environmental sustainability',
                linkText: '',
                href: '',
                image: {
                  file: {},
                  url: 'https://res.cloudinary.com/total-dealer/image/upload/v1/development/37d42uq4hpowxml61xbmefw0z9ex.jpg',
                },
              },
            ],
            type: 'shadow',
            rounded: true,
            imageHeight: null,
            imageWidth: null,
            cardCols: '',
            layoutType: 'customAspectRatio',
            objectFit: 'cover',
            aspectRatioHeight: 2,
            aspectRatioWidth: 3,
            btnType: 'fill',
            btnColour: 'primary',
            preview: true,
            starter: 'empty',
          },
          tunes: {
            alignment: {
              alignment: 'left',
            },
            container: {
              contain: false,
            },
            backgroundColor: {
              backgroundColor: 'light',
              customBackgroundColor: '#000000',
            },
            margin: {
              spacingTop: 0,
              spacingBottom: 0,
              mobile: {
                active: false,
                spacingTop: 0,
                spacingBottom: 0,
              },
              tablet: {
                active: false,
                spacingTop: 0,
                spacingBottom: 0,
              },
            },
            padding: {
              paddingTop: 0,
              paddingBottom: 5,
              mobile: {
                active: false,
                paddingTop: 0,
                paddingBottom: 0,
              },
              tablet: {
                active: false,
                paddingTop: 0,
                paddingBottom: 0,
              },
            },
            classname: {
              className: '',
            },
          },
        },
        {
          id: generateRandomId(10),
          type: 'locations',
          data: {
            filter: 'primary',
            manufacturer: false,
            subtype: false,
            filteredLocations: [],
            primaryLocationId: primaryLocation?.id,
          },
          tunes: {
            margin: {
              spacingTop: 0,
              spacingBottom: 0,
            },
            classname: {
              className: '',
            },
          },
        },
      ]
}
