import React, { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import Dialog from '../../common/Dialog'
import {
  EmptyPlaceHolder,
  ItemOption,
  LabeledInput,
  LabeledMultipleInput,
  LabeledSelect,
  LabeledMultipleSelectInput,
  LabeledCheckbox,
} from '../../common'
import Tooltip from '../../common/Tooltip'
import {
  capitalize,
  generateRandomId,
  removeDuplicateLocations,
  renderEditSettingsButton,
  renderHiddenModalButton,
  renderTabs,
} from '../../common/Utils'
import { unique } from '../../../entries/utils'
import {
  AddFieldTooltip,
  AddStepTooltip,
  CoreFieldsTooltip,
  CustomStepTooltip,
  DuplicateFieldDataKeyTooltip,
  DefaultCheckedTooltip,
  FieldDataKeyTooltip,
  FieldTypeTooltip,
  FormTypeTooltip,
  LeadCategoryTooltip,
  MoveFieldTooltip,
  MoveStepTooltip,
  MultiInputOptionsTooltip,
  MultiSelectOptionsTooltip,
  RequiredTooltip,
  SkipSendToServerTooltip,
  SpecialValueTooltip,
  HelpTooltip,
} from './FormBuilderTooltips'
import { Add, Info } from '../../common/Icons'
import { useForm, useFieldArray } from 'react-hook-form'

import FullFormPreview, { InputPreview } from './FormBuilderPreview'
import {
  inputOptions,
  categories,
  coreFields,
  filterFields,
  handleAddStep,
  handleDeleteStep,
  handleAddField,
  handleMoveField,
  FIELD_TYPE_INPUTS,
  getInputErrorClassNames,
  isReservedFormDataKey,
} from './formBuilderUtils'
import { formTemplates, getFormTemplate } from './FormBuilderTemplates'

const CoreFieldsDisplay = ({ fields }) => (
  <div className="rounded border bg-white px-3 pt-3 mb-3">
    <div className="row justify-content-center">
      <div className="col-12">
        <h6 className="text-muted d-flex align-items-center">
          CORE FIELDS
          <Tooltip
            tooltipTrigger={
              <div
                className="d-inline-flex justify-content-center align-items-center ml-1 mb-1"
                style={{ cursor: 'pointer' }}
              >
                <Info size="20px" stroke="#ff0000" />
              </div>
            }
            tooltipContent={CoreFieldsTooltip?.content}
            variant={CoreFieldsTooltip?.variant}
          />
        </h6>
      </div>
    </div>
    <div className="row">
      {fields.map((field, index) => (
        <div
          key={`core-input-preview-${generateRandomId(5)}`}
          className={index === 0 ? 'col-12' : 'col-12 col-md-6'}
        >
          <InputPreview item={field} disabled />
        </div>
      ))}
    </div>
  </div>
)

export const FieldEditor = ({
  index,
  item,
  activeStep,
  fields,
  steps,
  type,
  updateItem,
  deleteItem,
  move,
  locationOptions,
}) => {
  const [edit, setEdit] = useState(true)

  const editable = updateItem && edit

  useEffect(() => {
    // For NEW locationSelect fields, update the name to location_id
    if (item.type === 'locationSelect' && item.name === 'example') {
      updateItem(index, { ...item, name: 'location_id' })
    }
  }, [item.type])

  const handleMoveUp = () => {
    if (!move) return undefined

    // This logic really only matters if a user switched from a single step form to a multi-step form
    if (fields[fields.indexOf(item) - 1].stepKey !== item.stepKey) {
      updateItem(index, { ...item, stepKey: fields[fields.indexOf(item) - 1].stepKey })
    }

    // Move the field up
    move(index, index - 1)
  }

  // * Custom handling functions for multi input fields
  const handleAdd = (options, itemName, currentInput, setCurrentInput, setOptions) => {
    if (options.includes(currentInput)) {
      alert('You have already added ' + currentInput)
    } else if (currentInput.length > 0) {
      setOptions([...options, currentInput])
      updateItem(index, { ...item, [itemName]: [...options, currentInput] })
      setCurrentInput('')
    }
  }

  const handleDelete = (options, itemName, option, setOptions) => {
    const filteredOptions = options.filter((o) => o !== option)
    setOptions(filteredOptions)
    updateItem(index, { ...item, [itemName]: filteredOptions })
  }

  return (
    <ItemOption
      className="mb-3"
      edit={edit}
      index={index}
      item={item}
      title="Field"
      setEdit={setEdit}
      moveUp={
        filterFields(fields, type, steps[activeStep]?.key).indexOf(item) < 1
          ? undefined
          : () => handleMoveUp()
      }
      deleteItem={() => deleteItem(index)}
    >
      {editable ? (
        // Form field Inputs
        <div className="row">
          {item.type === 'rego' && (
            <div className="col-12 mb-3">
              <span className="badge badge-info">Info</span>{' '}
              <span className="small">
                The Rego input is only usable on its own within a step, no other fields may be
                added to the same step.
              </span>
            </div>
          )}
          <div className="col-12 col-md-6">
            <LabeledSelect
              item={item}
              itemName="type"
              customOnChange={(e) => updateItem(index, { ...item, type: e.target.value })}
              options={Array.from(inputOptions).filter((fieldType) =>
                // Disable rego if any other fields are present in the step & disable for single step forms
                filterFields(fields, type, steps[activeStep]?.key).length < 2 && type === 'multi'
                  ? true
                  : fieldType !== 'rego'
              )}
              tooltip={FieldTypeTooltip}
            />
          </div>

          {/* Field type specific inputs */}
          {FIELD_TYPE_INPUTS[item.type]?.includes('formDataKey') && (
            <div className="col-12 col-md-6">
              <LabeledInput
                inputClassName={getInputErrorClassNames(fields, item.name)}
                item={item}
                itemName="name"
                label="Form Data Key"
                customOnChange={(e) => {
                  if (!isReservedFormDataKey(e.target.value)) {
                    updateItem(index, { ...item, name: e.target.value })
                  }
                }}
                disabled={item.type === 'locationSelect'}
                tooltip={
                  // Show warning if data key is used multiple times
                  fields.filter((field) => field.name === item.name).length > 1
                    ? [FieldDataKeyTooltip, DuplicateFieldDataKeyTooltip]
                    : FieldDataKeyTooltip
                }
              />
            </div>
          )}
          {/* Custom for dateTime */}
          {FIELD_TYPE_INPUTS[item.type]?.includes('dateFormDataKey') && (
            <div className="col-12 col-md-6">
              <LabeledInput
                inputClassName={getInputErrorClassNames(fields, item.dateInputName)}
                item={item}
                itemName="dateInputName"
                label="Date Input Form Data Key"
                customOnChange={(e) => {
                  if (!isReservedFormDataKey(e.target.value)) {
                    updateItem(index, { ...item, dateInputName: e.target.value })
                  }
                }}
                tooltip={
                  // Show warning if data key is used multiple times
                  fields.filter((field) => field.name === item.dateInputName).length > 1
                    ? [FieldDataKeyTooltip, DuplicateFieldDataKeyTooltip]
                    : FieldDataKeyTooltip
                }
              />
            </div>
          )}
          {FIELD_TYPE_INPUTS[item.type]?.includes('timeFormDataKey') && (
            <div className="col-12 col-md-6">
              <LabeledInput
                inputClassName={getInputErrorClassNames(fields, item.timeInputName)}
                item={item}
                itemName="timeInputName"
                label="Time Input Form Data Key"
                customOnChange={(e) => {
                  if (!isReservedFormDataKey(e.target.value)) {
                    updateItem(index, { ...item, timeInputName: e.target.value })
                  }
                }}
                tooltip={
                  // Show warning if data key is used multiple times
                  fields.filter((field) => field.name === item.timeInputName).length > 1
                    ? [FieldDataKeyTooltip, DuplicateFieldDataKeyTooltip]
                    : FieldDataKeyTooltip
                }
              />
            </div>
          )}
          {FIELD_TYPE_INPUTS[item.type]?.includes('label') && (
            <div className="col-12 col-md-6">
              <LabeledInput
                item={item}
                itemName="label"
                customOnChange={(e) => updateItem(index, { ...item, label: e.target.value })}
              />
            </div>
          )}
          {FIELD_TYPE_INPUTS[item.type]?.includes('placeholder') && (
            <div className="col-12 col-md-6">
              <LabeledInput
                item={item}
                itemName="placeholder"
                customOnChange={(e) => updateItem(index, { ...item, placeholder: e.target.value })}
              />
            </div>
          )}
          {FIELD_TYPE_INPUTS[item.type]?.includes('defaultChecked') && (
            <div className="col-12 col-md-6">
              <div className="pl-4">
                <LabeledCheckbox
                  item={item}
                  itemName="defaultChecked"
                  customOnChange={(e) =>
                    updateItem(index, { ...item, defaultChecked: e.target.checked })
                  }
                  tooltip={DefaultCheckedTooltip}
                />
              </div>
            </div>
          )}
          {FIELD_TYPE_INPUTS[item.type]?.includes('help') && (
            <div className="col-12">
              <LabeledInput
                item={item}
                itemName="help"
                customOnChange={(e) => updateItem(index, { ...item, help: e.target.value })}
                tooltip={HelpTooltip}
              />
            </div>
          )}
          {FIELD_TYPE_INPUTS[item.type]?.includes('options') && (
            <div className="col-12">
              <LabeledMultipleInput
                item={item}
                itemName="options"
                customAdd={handleAdd}
                customDelete={handleDelete}
                tooltip={MultiInputOptionsTooltip}
              />
            </div>
          )}
          {FIELD_TYPE_INPUTS[item.type]?.includes('locationOptions') && (
            <div className="col-12">
              {item.type === 'locationSelect' && (
                <LabeledMultipleSelectInput
                  item={item}
                  itemName="options"
                  selectOptions={locationOptions}
                  customAdd={handleAdd}
                  customDelete={handleDelete}
                  tooltip={MultiSelectOptionsTooltip}
                />
              )}
            </div>
          )}
          {FIELD_TYPE_INPUTS[item.type]?.includes('specialValue') && (
            <div className="col-12 col-md-6">
              <LabeledInput
                item={item}
                itemName="specialValue"
                customOnChange={(e) => updateItem(index, { ...item, specialValue: e.target.value })}
                label="Special Value"
                placeholder="Enter Special Value..."
                tooltip={SpecialValueTooltip}
              />
            </div>
          )}

          {/* Always contain these 2 on their own row */}
          <div className="col-12">
            <div className="row justify-content-end">
              {FIELD_TYPE_INPUTS[item.type]?.includes('required') && (
                <div className="col-12 col-md-6">
                  <div className="pl-4">
                    <LabeledCheckbox
                      item={item}
                      itemName="required"
                      customOnChange={(e) =>
                        updateItem(index, { ...item, required: e.target.checked })
                      }
                      tooltip={RequiredTooltip}
                    />
                  </div>
                </div>
              )}
              {type === 'multi' && (
                <div className="col-12 col-md-6">
                  <LabeledSelect
                    item={
                      // Get the current step
                      steps.filter((step) => step.key === item?.stepKey)[0]
                    }
                    itemName="key"
                    label="Move to"
                    customOnChange={(e) => {
                      updateItem(index, { ...item, stepKey: parseInt(e.target.value) })
                      handleMoveField(e, move, index, steps, fields)
                    }}
                    // Map options from available steps
                    options={steps.map((step, index) => {
                      return { label: `Step ${index + 1}`, value: step.key }
                    })}
                    tooltip={MoveFieldTooltip}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        // Input field preview
        <InputPreview item={item} locationOptions={locationOptions} />
      )}
    </ItemOption>
  )
}

const RenderedFormBuilderComponent = ({ data, onDataChange, locations, uniqueId }) => {
  // Tool state management
  const [show, setShow] = useState(data?.templateSelected ? false : true)
  const [isTemplateSelected, setIsTemplateSelected] = useState(
    data?.templateSelected ? true : false
  )
  const [templateSelected, setTemplateSelected] = useState(data?.templateSelected)
  const [activeIndexState, setActiveIndexState] = useState({
    activeIndex: 0,
    previousActiveIndex: undefined,
  })
  const locationOptions = []
  locations.map((location) =>
    locationOptions.push({
      label: `${location.name} - ${capitalize(location.location_type ?? 'sales')}`,
      value: location.id,
    })
  )

  // Form setup
  const { control, setValue, watch } = useForm({
    defaultValues: {
      category: data?.category || 'General Enquiry',
      type: data?.type || 'single',
      heading: data?.heading || '',
      description: data?.description || '',
      fields: data?.fields || [],
      formSteps: data?.formSteps || [],
    },
  })

  // Form state management
  const category = watch('category')
  const type = watch('type')
  const heading = watch('heading')
  const description = watch('description')

  // Steps state management
  const {
    fields: formSteps,
    insert: insertStep,
    update: updateStep,
    remove: removeStep,
    move: moveStep,
  } = useFieldArray({
    control,
    name: 'formSteps',
  })

  // Fields state management
  const {
    fields: formFields,
    insert: insertField,
    update: updateField,
    remove: removeField,
    move: moveField,
  } = useFieldArray({
    control,
    name: 'fields',
  })

  // Save changes on Modal close
  useEffect(() => {
    onDataChange({
      category: category,
      type: type,
      heading: heading,
      description: description,
      fields: formFields,
      formSteps: formSteps,
    })
  }, [show])

  // Move step to selected index and fire manual handling of field ordering
  const handleMoveStep = (e, activeStep) => {
    // Move step
    moveStep(activeStep, e.target.value)

    // Change active index to match
    setActiveIndexState({
      activeIndex: parseInt(e.target.value),
      previousActiveIndex: activeStep,
    })
  }

  // Manually handle reordering fields when steps are reordered
  useEffect(() => {
    // Reorder fields to match
    let tempFieldsArr = [...formFields].sort((a, b) => {
      const aIndex = formSteps.findIndex((step) => step.key === a.stepKey)
      const bIndex = formSteps.findIndex((step) => step.key === b.stepKey)

      // If stepKey is not found in formSteps, treat it as if it were at the end
      if (aIndex === -1) return 1
      if (bIndex === -1) return -1

      return aIndex - bIndex
    })

    // Manually update fields value
    setValue('fields', tempFieldsArr)
  }, [formSteps])

  // Show warning if multi-step form has core fields after step 1
  const showWarning = (type, formSteps, warningType) => {
    switch (warningType) {
      case 'coreFields':
        if (type === 'multi' && getCoreFieldsStepIndex(formSteps) + 1 > 1) {
          return (
            <div className="col-12 mb-2">
              <span className="badge badge-warning">Warning</span>{' '}
              <span className="small">
                This form will not generate a lead until Step{' '}
                {getCoreFieldsStepIndex(formSteps) + 1} is completed. This is because the core
                fields are required to generate a lead.
              </span>
            </div>
          )
        } else {
          return undefined
        }
      case 'emptyStep':
        return formFields.filter(
          (field) =>
            field.stepKey === formSteps[activeIndexState.activeIndex].key && field.type !== 'hidden'
        ).length < 1 && !formSteps[activeIndexState.activeIndex].isCustom ? (
          <div className="mb-2">
            <span className="badge badge-warning">Warning</span>{' '}
            <span className="small">
              This step will not appear on your form because it contains no renderable fields.
            </span>
          </div>
        ) : undefined
      case 'duplicateDataKeys':
        const dataKeys = formFields.map((field) => field.name)
        if (dataKeys.length !== new Set(dataKeys).size) {
          return (
            <div className="col-12 mb-2">
              <span className="badge badge-warning">Warning</span>{' '}
              <span className="small">
                This form has multiple fields using the same form data key. This will break your
                form and lead, please update your fields so each form data key is unique.
              </span>
            </div>
          )
        } else {
          return undefined
        }
      default:
        return undefined
    }
  }

  function getSkipSendToServerCustomValue(activeIndex, steps) {
    // If final step, selection is false
    if (activeIndex === steps.length - 1) return false

    // If step is before core fields step, selection is true
    if (activeIndex < getCoreFieldsStepIndex(steps)) return true

    return undefined
  }

  function getCoreFieldsStepIndex(steps) {
    return steps.indexOf(steps.filter((step) => step.key === 0)[0])
  }

  return (
    <>
      {!isTemplateSelected ? (
        <div className="col-12 mb-2">
          <span className="badge badge-warning">Warning</span>{' '}
          <span className="small">Please select a template to begin building your form.</span>
        </div>
      ) : (
        <FullFormPreview
          type={type}
          heading={heading}
          description={description}
          fields={formFields}
          steps={formSteps.filter((step) => {
            if (
              formFields.filter((field) => field.stepKey === step.key && field.type !== 'hidden')
                .length > 0 ||
              step.isCustom
            )
              return step
          })}
          locationOptions={locationOptions}
        />
      )}
      <Dialog
        title="Form Builder"
        show={show}
        closeClickHandler={() => {
          // Custom handling for duplicate data keys
          const dataKeys = formFields.map((field) => field.name)
          if (dataKeys.length !== new Set(dataKeys).size) {
            alert(
              'Your form contains duplicate data keys. Please update your fields so that all form data keys are unique.'
            )
          } else {
            setShow(false)
          }
        }}
        documentationUrl="https://secure.helpscout.net/docs/659d292d3afe0a1c1e4d13a0/article/65e7ef89cbe4ec20ccca089a/"
      >
        {!isTemplateSelected ? (
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="template-option" className="form-label">
                  Select a template option
                </label>
                <select
                  id="template-option"
                  className="form-control"
                  defaultValue="placeholder"
                  onChange={(e) => setTemplateSelected(e.target.value)}
                >
                  <option disabled value="placeholder">
                    Select a template
                  </option>
                  {formTemplates?.map((template, index) => (
                    <option key={`template-${index}`} value={template.value}>
                      {template.name}
                    </option>
                  ))}
                  <option value="custom">Custom</option>
                </select>
              </div>
              {templateSelected && (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    // If it's not a custom form, get the template data and set the form values
                    if (templateSelected !== 'custom') {
                      const templateData = getFormTemplate(templateSelected)
                      setValue('type', templateData.type)
                      setValue('category', templateData.category)
                      setValue('heading', templateData.heading)
                      setValue('description', templateData.description)
                      setValue('formSteps', templateData.formSteps)
                      setValue('fields', templateData.fields)
                    }
                    onDataChange({ templateSelected: templateSelected })

                    // Set the templateSelected state to true
                    setIsTemplateSelected(true)
                  }}
                >
                  Get Started
                </button>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-12">
                <h5>Form Settings</h5>
              </div>
              <div className="col-12 col-md-6">
                <LabeledSelect
                  item={{ type: type }}
                  itemName="type"
                  label="Form Type"
                  updateItem={(item) => setValue('type', item.type)}
                  options={[
                    { label: 'Single Step', value: 'single' },
                    { label: 'Multi Step', value: 'multi' },
                  ]}
                  tooltip={FormTypeTooltip}
                />
              </div>
              <div className="col-12 col-md-6">
                <LabeledSelect
                  item={{ category: category }}
                  itemName="category"
                  label="Lead Category"
                  updateItem={(item) => setValue('category', item.category)}
                  options={Array.from(categories)}
                  tooltip={LeadCategoryTooltip}
                />
              </div>
              {type === 'single' && (
                <>
                  <div className="col-12 col-md-6">
                    <LabeledInput
                      controlled={false}
                      item={{ heading: heading }}
                      itemName="heading"
                      label="Form Heading"
                      customOnChange={(e) => {
                        setValue('heading', e.target.value)
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <LabeledInput
                      controlled={false}
                      item={{ description: description }}
                      itemName="description"
                      label="Form Description"
                      customOnChange={(e) => setValue('description', e.target.value)}
                    />
                  </div>
                </>
              )}
              {showWarning(type, formSteps, 'coreFields')}
              {showWarning(type, formSteps, 'duplicateDataKeys')}
            </div>
            {/* Render Step Tabs */}
            {type === 'multi' && (
              <>
                <div className="border-top pt-3"></div>
                {renderTabs(
                  formSteps.map((step, index) => {
                    return {
                      name:
                        step.isCustom && step.customStepKey
                          ? step.customStepKey
                          : `Step ${index + 1}`,
                    }
                  }),
                  activeIndexState,
                  setActiveIndexState,
                  formSteps.map((step, index) => index),
                  <Tooltip
                    tooltipTrigger={
                      <button
                        className="btn btn-small btn-success d-flex align-items-center ml-2"
                        onClick={() =>
                          handleAddStep(
                            formSteps,
                            activeIndexState.activeIndex,
                            insertStep,
                            setActiveIndexState
                          )
                        }
                      >
                        <Add size={25} />
                      </button>
                    }
                    tooltipContent={AddStepTooltip?.content}
                    width={200}
                  />
                )}
              </>
            )}
            {/* Render step inputs */}
            {type === 'multi' && (
              <div className="row mt-3">
                <div className="col-12">
                  <h5>Step Settings</h5>
                </div>
                <div className="col-12 col-md-6">
                  <LabeledInput
                    item={formSteps[activeIndexState.activeIndex]}
                    itemName="heading"
                    label="Step Heading"
                    customOnChange={(e) => {
                      updateStep(activeIndexState.activeIndex, {
                        ...formSteps[activeIndexState.activeIndex],
                        heading: e.target.value,
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <LabeledInput
                    item={formSteps[activeIndexState.activeIndex]}
                    itemName="description"
                    label="Step Description"
                    customOnChange={(e) => {
                      updateStep(activeIndexState.activeIndex, {
                        ...formSteps[activeIndexState.activeIndex],
                        description: e.target.value,
                      })
                    }}
                  />
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex flex-column">
                      <div className="pl-4">
                        <LabeledCheckbox
                          item={formSteps[activeIndexState.activeIndex]}
                          label="Skip lead generation/update for this step"
                          itemName="skipSendToServer"
                          customOnChange={(e) =>
                            updateStep(formSteps.indexOf(formSteps[activeIndexState.activeIndex]), {
                              ...formSteps[activeIndexState.activeIndex],
                              skipSendToServer: e.target.checked,
                            })
                          }
                          tooltip={SkipSendToServerTooltip}
                          // Custom values for disabled state
                          customValue={getSkipSendToServerCustomValue(
                            activeIndexState.activeIndex,
                            formSteps
                          )}
                          // Disable if step is before core fields step or is the final step
                          disabled={
                            activeIndexState.activeIndex < getCoreFieldsStepIndex(formSteps) ||
                            activeIndexState.activeIndex === formSteps.length - 1
                          }
                        />
                      </div>
                      <div className="pl-4">
                        <LabeledCheckbox
                          item={formSteps[activeIndexState.activeIndex]}
                          label="Is this a custom step?"
                          itemName="isCustom"
                          customOnChange={(e) =>
                            updateStep(formSteps.indexOf(formSteps[activeIndexState.activeIndex]), {
                              ...formSteps[activeIndexState.activeIndex],
                              isCustom: e.target.checked,
                            })
                          }
                          tooltip={CustomStepTooltip}
                        />
                      </div>
                      <div
                        className={`d-${
                          !formSteps[activeIndexState.activeIndex]?.isCustom ? 'none' : 'block'
                        }`}
                      >
                        <LabeledInput
                          item={formSteps[activeIndexState.activeIndex]}
                          itemName="customStepKey"
                          label="Custom Step Key"
                          customOnChange={(e) =>
                            updateStep(formSteps.indexOf(formSteps[activeIndexState.activeIndex]), {
                              ...formSteps[activeIndexState.activeIndex],
                              customStepKey: e.target.value,
                            })
                          }
                          disabled={!formSteps[activeIndexState.activeIndex]?.isCustom}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="col-12">
                          <LabeledSelect
                            item={
                              formSteps
                                // Get index & key of all steps
                                .map((step, index) => {
                                  return { index: index, key: step.key }
                                })
                                // Get the current step
                                .filter(
                                  (step) =>
                                    step.key === formSteps[activeIndexState.activeIndex]?.key
                                )[0]
                            }
                            itemName="index"
                            label="Change step position"
                            customOnChange={(e) => {
                              handleMoveStep(e, activeIndexState.activeIndex)
                            }}
                            // Map options from available steps
                            options={formSteps.map((step, index) => {
                              return { label: index + 1, value: index }
                            })}
                            tooltip={MoveStepTooltip}
                          />
                        </div>
                        <div
                          className={`col-12 d-${
                            // Can't delete if only 1 step, or step contains Core Fields
                            formSteps.length === 1 ||
                            formSteps[activeIndexState.activeIndex]?.key === 0
                              ? 'none'
                              : 'flex'
                          } align-items-end`}
                        >
                          <button
                            className="btn btn-danger btn-block mb-3"
                            onClick={() =>
                              handleDeleteStep(
                                activeIndexState.activeIndex,
                                formSteps,
                                formFields,
                                removeField,
                                removeStep,
                                setActiveIndexState
                              )
                            }
                          >
                            Delete Step
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* Render step field components */}
            <div className="border-top pt-3">
              <h5>Field Settings</h5>
            </div>
            {showWarning(type, formSteps, 'emptyStep')}
            {!formSteps[activeIndexState.activeIndex]?.isCustom ? (
              <>
                {/* // ! Eventually want to enable moving Core Fields up/down */}
                {(formSteps[activeIndexState.activeIndex]?.key === 0 || type === 'single') && (
                  <CoreFieldsDisplay
                    fields={data?.fields?.filter(
                      (field) =>
                        field.name === 'name' || field.name === 'phone' || field.name === 'email'
                    )}
                  />
                )}
                {formFields.length > 0 ? (
                  filterFields(formFields, type, formSteps[activeIndexState.activeIndex]?.key).map(
                    (field, id) => (
                      <FieldEditor
                        key={id}
                        index={formFields.indexOf(field)}
                        item={field}
                        activeStep={activeIndexState.activeIndex}
                        fields={formFields}
                        steps={formSteps}
                        type={type}
                        updateItem={updateField}
                        deleteItem={removeField}
                        move={moveField}
                        locationOptions={locationOptions}
                      />
                    )
                  )
                ) : (
                  <div className="mt-3">
                    <EmptyPlaceHolder itemName="extra field" />
                  </div>
                )}
                {/* Rego is only allowed on its own, remove button if it exists on the step */}
                {formFields.length > 0 &&
                  filterFields(
                    formFields,
                    type,
                    formSteps[activeIndexState.activeIndex]?.key
                  ).filter((field) => field.type === 'rego').length === 0 && (
                    <Tooltip
                      tooltipTrigger={
                        <button
                          className="btn btn-small btn-primary d-flex align-items-center mx-auto mt-3"
                          onClick={() =>
                            handleAddField(
                              activeIndexState.activeIndex,
                              formSteps,
                              type,
                              formFields,
                              insertField
                            )
                          }
                        >
                          <Add /> <span className="ml-1">Field</span>
                        </button>
                      }
                      tooltipContent={AddFieldTooltip?.content}
                      width={200}
                    />
                  )}
              </>
            ) : (
              <p className="text-danger mb-0">Custom steps can't have any extra fields.</p>
            )}
          </>
        )}
      </Dialog>
      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
    </>
  )
}

class FormBuilderTool {
  constructor({ data, config, api }) {
    this.api = api
    this.config = config
    this.uniqueId = unique()
    this.locations = this.config.locations ? removeDuplicateLocations(this.config.locations) : []

    const defaultData = {
      type: 'single',
      category: 'General Enquiry',
      heading: 'Make an enquiry',
      description: '',
      formSteps: [
        {
          key: 0,
          isCustom: undefined,
          customStepKey: undefined,
          heading: '',
          description: '',
          skipSendToServer: false, // Core-fields step cannot be skipped
        },
      ], // Default for multi-step forms
      fields: coreFields,
      templateSelected: false,
    }

    this.data = Object.keys(data).length ? data : defaultData

    // * Handle adding required field to existing fields
    if (this.data.fields.length > 0) {
      this.data.fields.forEach((field) => {
        // Only apply to fields that don't have a required key (i.e. old fields)
        if (field?.required === undefined) {
          field.required = true
        }
        // Only apply to fields that don't have a stepKey
        if (field?.stepKey === undefined) {
          field.stepKey = 0
        }
      })
    }

    // * Handle adding type, description and formSteps to existing instances
    if (this.data.type === undefined) {
      this.data.type = 'single'
      this.data.description = ''
      this.data.formSteps = [{ key: 0, customStepKey: undefined, heading: '', description: '' }]
    }

    // * Handle adding templateSelected to existing instances
    if (this.data.templateSelected === undefined) {
      this.data.templateSelected = 'custom'
    }

    // * Convert Single step forms with Rego input to Multi with Rego input on the first step
    if (
      this.data.type === 'single' &&
      this.data.fields.filter((field) => field.type === 'rego').length > 0
    ) {
      // Convert to multi-step form
      this.data.type = 'multi'
      // Add a new step for the rego input
      this.data.formSteps = [
        {
          key: 1,
          isCustom: undefined,
          customStepKey: undefined,
          heading: this.data.heading,
          description: this.data.description,
          skipSendToServer: true,
        },
        {
          key: 0,
          isCustom: undefined,
          customStepKey: undefined,
          heading: this.data.heading,
          description: this.data.description,
          skipSendToServer: false, // Core-fields step cannot be skipped
        },
      ]
      // Move the rego input to the first step
      this.data.fields = this.data.fields.map((field) => {
        return {
          ...field,
          stepKey: field.type === 'rego' ? 1 : 0,
        }
      })
    }

    this.CSS = {
      wrapper: 'walkthrough-timeline',
    }

    this.nodes = {
      holder: null,
    }
  }

  static get toolbox() {
    return {
      title: 'Form Builder',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
      </svg>`,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    rootNode.setAttribute('class', this.CSS.wrapper)
    this.nodes.holder = rootNode

    const onDataChange = (newData) => {
      this.data = {
        ...this.data,
        ...newData,
      }
    }
    const root = createRoot(rootNode)
    root.render(
      <RenderedFormBuilderComponent
        onDataChange={onDataChange}
        data={this.data}
        locations={this.locations}
        uniqueId={this.uniqueId}
      />
    )
    return this.nodes.holder
  }

  /** Create the settings panel for the block */
  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  save() {
    return this.data
  }
}

export default FormBuilderTool
