import { useState, useEffect } from "react";
import * as ReactDOM from 'react-dom/client';
import Select from 'react-select'
import { range, ready } from './entries/utils'
import moment from 'moment'

function VehicleDetails(v) {
  // Create an array of the values to display, conditionally including them only if they exist
  const details = [
    v.bodystyle,
    v.enginetype,
    v.geartype,
    v.fueltype,
    v.drive,
    v.enginesizelitres && `Engine Size (L) - ${v.enginesizelitres}`,
    v.doorno && `Doors - ${v.doorno}`,
    v.yeargroupmonthname && v.yeargroupyear && `${v.yeargroupmonthname} ${v.yeargroupyear}`,
    v.bodyconfiguration
  ].filter(Boolean); // Remove any falsy values from the array (e.g., '', null, undefined)

  // Join the non-empty values with ' | ' to create the final string
  const detailString = details.join(' | ');

  return <p className="text-secondary">{detailString}</p>;
}

const Variants = ({ variants }) => {
  const getUrl = (v) => {
    if (typeof car_slug !== 'undefined') {
      return `/cars/${car_slug}/edit?variant_id=${v.id}`
    } else {
      return `/dealerships/${dealer_slug}/cars/new?variant_id=${v.id}`
    }
  }
  return (
    <>
      <h4>
        Results
        <small className="text-secondary mb-2 float-right small">
          {variants.length} results found
        </small>
      </h4>
      {variants.length === 0 && (<p className="text-secondary">No Results Found</p>)}
      {variants.map(v => (
        <div key={v.id} className="box p-3 mb-2">
          <div className="row">
            <div className="col-md-9">
              <div className="mb-2">
                <h5 className="mb-0">{v.yeargroupyear} {v.familyname} {v.badge !== '(No Badge)' && v.badge} {v.series} {v.geartype} {v.modelyear}</h5>
                <small className="text-secondary">{v.description}</small>
              </div>
              <p className="text-secondary">
                {VehicleDetails(v)}
              </p>
            </div>
            <div className="col-md-3">
              <div className="float-right">
                <a
                  className="btn btn-outline-primary"
                  href={getUrl(v)}
                >
                  Select
                </a>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

const App = ({
  manufacturer_id,
  family_id,
  title,
  subtitle,
  defaultYear,
  drive_options,
  transmission_options,
  fuel_options,
  defaultBadge,
  build_date
}) => {
  let [manufacturer, setManufacturer] = useState(manufacturer_id)
  let [family, setFamily] = useState()
  let [year, setYear] = useState()
  let [families, setFamilies] = useState()
  let [familyOptions, setFamilyOptions] = useState()
  let [yearOptions, setYearOptions] = useState()
  let [variants, setVariants] = useState()
  let [facets, setFacets] = useState()
  let [geartype, setGeartype] = useState()
  let [badge, setBadge] = useState()
  let [fueltype, setFueltype] = useState()
  let [bodystyle, setBodystyle] = useState()
  let [drive, setDrive] = useState()
  let [bodyconfiguration, setBodyconfiguration] = useState()
  let [loading, setLoading] = useState(false)
  let manufacturer_options = window.manufacturers.map(man => { return { value: man.id, label: man.name } })
  let families_url = `/manufacturers/${manufacturer}/families.json`

  useEffect(() => {
    setFamily(null)
    setYear(null)
    setYearOptions(null)
    resetFilters()
    if (manufacturer) {
      setFamilies(null)
      setFamilyOptions(null)
      fetch(families_url)
        .then(res => res.json())
        .then(res => {
          setFamilies(res)
          setFamilyOptions(res.map(m => { return { value: m.id, label: m.name } }))
          if (family_id) {
            setFamily(family_id)
          }
        })
    }
  }, [manufacturer]);

  useEffect(() => {
    setYear(null)
    resetFilters()
    if (family && families) {
      let current_family = families.filter(f => f.id == family)[0]
      if (current_family && current_family.year_start) {
        let years = range(current_family.year_start, current_family.year_end, 1)
        setYearOptions(years.map(y => { return { value: y, label: y } }).reverse())
        if (defaultYear) {
          setYear(parseInt(defaultYear))
        }
      }
    }
  }, [family]);

  const resetFilters = () => {
    setGeartype(null)
    setBadge(null)
    setFueltype(null)
    setBodystyle(null)
  }

  const search = () => {
    setLoading(true)
    setFacets({})
    resetFilters()
    let params = {
      manufacturer_id: manufacturer,
      family_id: family,
      year: year
    }
    let searchParams = new URLSearchParams(params).toString()
    let variant_serach_url = `/variants/search?${searchParams}`
    fetch(variant_serach_url)
      .then(res => res.json())
      .then(data => {
        setVariants(data.variants)
        if (data.facets) {
          setFacets(data.facets)
        }

        if (defaultBadge && data.facets?.badge && data.facets?.badge.includes(defaultBadge)) {
          setBadge(defaultBadge)
        }

        if (drive_options && drive_options.length > 0) {
          let d_options = JSON.parse(drive_options)
          if (d_options !== null && typeof d_options !== undefined) {
            let variant_drive_options = data.variants.map(v => v.drive)
            let default_drive_option = d_options.filter(d => variant_drive_options.includes(d))
            if (default_drive_option.length === 1) {
              setDrive(default_drive_option[0])
            }
          }
        }

        if (transmission_options && transmission_options.length > 0) {
          let t_options = JSON.parse(transmission_options)
          if (t_options) {
            let variant_transmission_options = data.variants.map(v => v.geartype)
            let default_trans_option = t_options.filter(d => variant_transmission_options.includes(d))
            if (default_trans_option.length === 1) {
              setGeartype(default_trans_option[0])
            }
          }
        }

        if (fuel_options && fuel_options.length > 0) {
          let t_options = JSON.parse(fuel_options)
          if (t_options) {
            let fuel_options = data.variants.map(v => v.fueltype)
            let default_fuel_option = t_options.filter(d => fuel_options.includes(d))
            if (default_fuel_option.length === 1) {
              setFueltype(default_fuel_option[0])
            }
          }
        }
        setLoading(false)
      })
  }

  // Apply filters
  let filterdVariants = variants
  let filters = [
    { name: 'geartype', value: geartype },
    { name: 'badge', value: badge },
    { name: 'fueltype', value: fueltype },
    { name: 'bodystyle', value: bodystyle },
    { name: 'drive', value: drive },
    { name: 'bodyconfiguration', value: bodyconfiguration },
  ]
  filters.filter(f => f.value).map(filter => {
    filterdVariants = filterdVariants.filter(v => v[filter.name] === filter.value)
  })

  let filteredVariants_except_badge = variants || []
  let filteredVariants_except_geartype = variants || []
  let filteredVariants_except_fueltype = variants || []
  let filteredVariants_except_bodystyle = variants || []
  let filteredVariants_except_drive = variants || []
  let filteredVariants_except_bodyconfiguration = variants || []

  filters.filter(f => f.value).filter(f => f.name != 'badge').map(f => {
    filteredVariants_except_badge = filteredVariants_except_badge.filter(v => v[f.name] === f.value)
  })

  filters.filter(f => f.value).filter(f => f.name != 'geartype').map(f => {
    filteredVariants_except_geartype = filteredVariants_except_geartype.filter(v => v[f.name] === f.value)
  })

  filters.filter(f => f.value).filter(f => f.name != 'fueltype').map(f => {
    filteredVariants_except_fueltype = filteredVariants_except_fueltype.filter(v => v[f.name] === f.value)
  })

  filters.filter(f => f.value).filter(f => f.name != 'bodystyle').map(f => {
    filteredVariants_except_bodystyle = filteredVariants_except_bodystyle.filter(v => v[f.name] === f.value)
  })

  filters.filter(f => f.value).filter(f => f.name != 'drive').map(f => {
    filteredVariants_except_drive = filteredVariants_except_drive.filter(v => v[f.name] === f.value)
  })

  filters.filter(f => f.value).filter(f => f.name != 'bodyconfiguration').map(f => {
    filteredVariants_except_bodyconfiguration = filteredVariants_except_bodyconfiguration.filter(v => v[f.name] === f.value)
  })

  return (
    <div className="row">
      <div className="col-md-4 col-xl-3">
        <h1 className="h4">{manufacturer_id ? "Edit Car" : "New Car"}</h1>
        {title && <p>{title}</p>}
        {subtitle && <p className="small text-secondary">{subtitle}</p>}
        {build_date && <p className="small text-secondary">Build Date: {moment(build_date).format('DD/MM/YYYY')}</p>}
        <div className="box px-3 py-4 mb-3">
          <div className="mb-3">
            <Select
              options={manufacturer_options}
              onChange={e => setManufacturer(e.value)}
              placeholder="Make..."
              defaultValue={manufacturer_options.filter(o => o.value == manufacturer_id)}
            />
          </div>
          <div className="mb-3">
            <Select
              options={familyOptions}
              isDisabled={!familyOptions}
              onChange={e => setFamily(e.value)}
              value={familyOptions && familyOptions.filter(o => o.value == family)}
              placeholder="Model..."
            />
          </div>
          <div className="mb-3">
            <Select
              isDisabled={!yearOptions}
              options={yearOptions}
              value={yearOptions && yearOptions.filter(o => o.value === year)}
              onChange={e => setYear(e.value)}
              placeholder="Year..."
            />
          </div>
          {manufacturer && family && year && (
            <div className={"btn btn-outline-primary btn-block mt-3" + (loading ? ' disabled' : '')} onClick={search} disabled={loading}>
              {loading ? 'Loading...' : 'Next'}
            </div>
          )}
        </div>
        {facets && <div className="box p-3">
          <div className="text-center mb-2">
            <span className="small text-secondary">Additional filters</span>
          </div>
          {facets?.badge && (
            <div className="mb-3">
              <Select
                options={facets?.badge.filter(b => filteredVariants_except_badge.some(v => v.badge === b)).map(b => { return { label: b, value: b } })}
                onChange={e => setBadge(e?.value)}
                value={badge ? { label: badge, value: badge } : null}
                isClearable={true}
                backspaceRemovesValue={true}
                placeholder="Badge..."
              />
            </div>
          )}
          {facets?.geartype && <div className="mb-3">
            <Select
              options={facets?.geartype.filter(gt => filteredVariants_except_geartype.some(v => v.geartype === gt)).map(gt => { return { label: gt, value: gt } })}
              onChange={e => setGeartype(e?.value)}
              value={geartype ? { label: geartype, value: geartype } : null}
              isClearable={true}
              backspaceRemovesValue={true}
              placeholder="Geartype..."
            />
          </div>}
          {facets?.fueltype && (
            <div className="mb-3">
              <Select
                options={facets?.fueltype.filter(b => filteredVariants_except_fueltype.some(v => v.fueltype === b)).map(b => { return { label: b, value: b } })}
                onChange={e => setFueltype(e?.value)}
                value={fueltype ? { label: fueltype, value: fueltype } : null}
                isClearable={true}
                backspaceRemovesValue={true}
                placeholder="Fuel..."
              />
            </div>
          )}
          {facets?.bodystyle && (
            <div className="mb-3">
              <Select
                options={facets?.bodystyle.filter(b => filteredVariants_except_bodystyle.some(v => v.bodystyle === b)).map(b => { return { label: b, value: b } })}
                onChange={e => setBodystyle(e?.value)}
                isClearable={true}
                backspaceRemovesValue={true}
                placeholder="Bodystyle..."
              />
            </div>
          )}
          {facets?.drive && (
            <div className="mb-3">
              <Select
                options={facets?.drive.filter(b => filteredVariants_except_drive.some(v => v.drive === b)).map(b => { return { label: b, value: b } })}
                onChange={e => setDrive(e?.value)}
                value={drive ? { label: drive, value: drive } : null}
                isClearable={true}
                backspaceRemovesValue={true}
                placeholder="Drive..."
              />
            </div>
          )}
          {facets?.bodyconfiguration && (
            <div className="mb-3">
              <Select
                options={facets?.bodyconfiguration.filter(b => filteredVariants_except_bodyconfiguration.some(v => v.bodyconfiguration === b)).map(b => { return { label: b, value: b } })}
                onChange={e => setBodyconfiguration(e?.value)}
                value={bodyconfiguration ? { label: bodyconfiguration, value: bodyconfiguration } : null}
                isClearable={true}
                backspaceRemovesValue={true}
                placeholder="Body Configuration..."
              />
            </div>
          )}
        </div>}
        {window.rails_action && rails_action === "new" && (
          <a
            href={`/dealerships/${dealer_slug}/cars/new?manual=true`}
            className="btn btn-sm btn-outline-secondary btn-block mt-2"
          >
            Manually Add Car
          </a>
        )}
      </div>
      <div className="col-md-8 col-xl-9">
        {variants && <Variants variants={filterdVariants ? filterdVariants : variants} />}
      </div>
    </div>
  )
}

ready(function () {
  let rootElement = document.getElementById('app-base')
  if (rootElement) {
    const manufacturer_id = rootElement.getAttribute('manufacturer_id')
    const family_id = rootElement.getAttribute('family_id')
    const title = rootElement.getAttribute('title')
    const subtitle = rootElement.getAttribute('subtitle')
    const defaultYear = rootElement.getAttribute('defaultYear')
    const drive_options = rootElement.getAttribute('drive_options')
    const transmission_options = rootElement.getAttribute('transmission_options')
    const build_date = rootElement.getAttribute('build_date')
    const fuel_options = rootElement.getAttribute('fuel_options')
    const badge = rootElement.getAttribute('badge')
    const root = ReactDOM.createRoot(rootElement)
    root.render(
      <App
        manufacturer_id={manufacturer_id}
        family_id={family_id}
        title={title}
        subtitle={subtitle}
        defaultYear={defaultYear}
        drive_options={drive_options}
        transmission_options={transmission_options}
        fuel_options={fuel_options}
        build_date={build_date}
        defaultBadge={badge}
      />
    );
  }
})
