import EditorJsRenderer from '../EditorJsRenderer'
import { generateRandomId } from '../../Utils'
import { COL_KEYS } from '../../../tools/AlternatingColumnsTool/utils'

const AlternatingColumnsBlock = ({ block }) => {
  return (
    <div className="container-fluid">
      {block?.columns?.map((row, index) => {
        // Handles reverse logic
        const textBlocks = block.reverse ? row[COL_KEYS[1]]?.blocks : row[COL_KEYS[0]]?.blocks
        const imgBlocks = block.reverse ? row[COL_KEYS[0]]?.blocks : row[COL_KEYS[1]]?.blocks

        return (
          <div
            key={`alternating-columns-row-${index}-${generateRandomId(5)}`}
            className={
              block?.variant ? (index % 2 === 0 ? 'bg-light' : 'bg-white') + ' row py-4' : 'row'
            }
          >
            <div className="col-12">
              <div className="container">
                <div className={`row ${block?.variant ? 'bg-white border border-light' : ''}`}>
                  <div
                    className={`col-12 mb-3 col-md-6 mb-md-0 ${
                      (index % 2 === 0 && !block.reverse) || (index % 2 === 1 && block.reverse)
                        ? 'd-flex flex-column align-items-center justify-content-center order-2 order-md-1'
                        : 'order-1'
                    } ${
                      ((index % 2 === 1 && !block.reverse) || (index % 2 === 0 && block.reverse)) &&
                      block?.variant
                        ? 'px-0'
                        : 'px-lg-0'
                    }`}
                  >
                    <EditorJsRenderer
                      data={{
                        blocks: index % 2 === 0 ? textBlocks : imgBlocks,
                      }}
                      isAlternatingColumns
                    />
                  </div>
                  <div
                    className={`col-12 mb-3 col-md-6 mb-md-0 ${
                      (index % 2 === 1 && !block.reverse) || (index % 2 === 0 && block.reverse)
                        ? 'd-flex flex-column align-items-center justify-content-center order-2 order-md-1'
                        : 'order-1 order-md-2'
                    } ${
                      ((index % 2 === 0 && !block.reverse) || (index % 2 === 1 && block.reverse)) &&
                      block?.variant
                        ? 'px-0'
                        : 'px-lg-0'
                    }`}
                  >
                    <EditorJsRenderer
                      data={{
                        blocks: index % 2 === 0 ? imgBlocks : textBlocks,
                      }}
                      isAlternatingColumns
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default AlternatingColumnsBlock
